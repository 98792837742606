declare global {
    interface Window {
        showLoader: (message?: string, timeout?: number) => JQuery;
        hideLoader: () => JQuery;
    }
}

const loader = $("div.loading");
const $progress = loader.find<HTMLProgressElement>("progress");
const load_message = loader.find(".message");

let pending_timeout: number = null;

function showLoader(
    message = "",
    timeout = 5000,
    progress: number = null,
    maxProgress: number = null,
) {
    if (pending_timeout) {
        clearTimeout(pending_timeout);
    }

    load_message.text(message);
    pending_timeout = window.setTimeout(hideLoader, timeout);

    if (progress != null && maxProgress != null) {
        $progress.show();

        $progress.get(0).value = progress;
        $progress.get(0).max = maxProgress;
    } else {
        $progress.hide();
    }

    return loader.fadeIn("fast");
}

function hideLoader() {
    if (pending_timeout) {
        clearTimeout(pending_timeout);
    }

    return loader.fadeOut("fast");
}

window.showLoader = showLoader;
window.hideLoader = hideLoader;

export { showLoader, hideLoader };
