/* FIXME: BS5 */
$(function () {
    const dropTargets = $(".droppable");

    if (dropTargets.length === 0 || window.FileReader === undefined) {
        dropTargets.addClass("not-droppable");
        return;
    }

    $(document)
        .on("drag dragstart dragend dragover dragenter dragleave drop", function (e) {
            e.preventDefault();
            e.stopPropagation();
        })
        .on("dragstart dragenter dragover", function () {
            dropTargets.addClass("is-dragging");
        })
        .on("dragleave dragend drop", function () {
            dropTargets.removeClass("is-dragging");
        });

    dropTargets
        .on("dragover dragenter", function () {
            dropTargets.addClass("is-dragover");
        })
        .on("dragleave dragend drop", function () {
            dropTargets.removeClass("is-dragover");
        });

    //$(document.body)
    //    .on('drag dragstart dragend dragover dragenter dragleave drop', function (e) {
    //      console.log(e)
    //      e.preventDefault();
    //      e.stopPropagation();
    //    })
    //    .on('dragover dragenter', function () {
    //      dropTargets.addClass('is-dragover');
    //    })
    //    .on('dragleave dragend drop', function () {
    //      dropTargets.removeClass('is-dragover');
    //    })
    //    .on('drop', function (e) {
    //      droppedFiles = e.originalEvent.dataTransfer.files;
    //    });

    console.log("Drag and drop enabled", dropTargets);
});
