/* FIXME: BS5 */
(function (select2) {
    select2.property_group = {
        allowClear: true,
        closeOnSelect: false,
        minimumInputLength: 0,
        ajax: {
            url: () => router.route("api.internal.organization.groups"),
            dataType: "json",
            quietMillis: 100,
            data: (term, page) => ({ term, page }),
            results: (data) => data,
        },
        formatResult: function (group) {
            if (this.processResult) {
                this.processResult(group);
            }
            return `<strong>${group.name}</strong>`;
        },
        formatSelection: function (group) {
            if (this.processSelection) {
                this.processSelection(group);
            }
            return group.name;
        },
        initSelection: select2.makeInitSelection((args) =>
            router.route("api.internal.organization.groups", args),
        ),
    };

    select2.property_group_multiple = $.extend(
        {
            multiple: true,
        },
        select2.property_group,
    );
})(window.comanage.select2);
