/* FIXME: BS5 */
(function (select2) {
    select2.listing = {
        allowClear: true,
        closeOnSelect: false,
        minimumInputLength: 0,
        ajax: {
            url: () => router.route("api.internal.marketplace"),
            dataType: "json",
            quietMillis: 100,
            data: (term, page) => ({ term, page }),
            results: (data) => data,
        },
        formatResult: function (listing) {
            if (this.processResult) {
                this.processResult(listing);
            }
            return $f.listing_short(listing);
        },
        formatSelection: function (listing) {
            if (this.processSelection) {
                this.processSelection(listing);
            }
            return $f.listing_short(listing);
        },
        initSelection: select2.makeInitSelection((args) =>
            router.route("api.internal.marketplace.listing", args),
        ),
    };

    select2.listing_multiple = $.extend({ multiple: true }, select2.listing);
})(window.comanage.select2);
