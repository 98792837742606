/* FIXME: BS5 */
import DOMPurify from "dompurify";
import EasyMDE from "easymde";

const ALLOWED_TAGS = ["b", "i", "u", "em", "strong", "a"];

$(function () {
    $("[data-easymde]").each(function () {
        const mde = new EasyMDE({
            element: this,
            autoDownloadFontAwesome: false,
            autofocus: false,
            forceSync: true,
            shortcuts: { toggleFullScreen: null },
            spellChecker: false,
            status: false,
            toolbar: false,
            minHeight: "2rem",

            renderingConfig: {
                sanitizerFunction(renderedHtml) {
                    return DOMPurify.sanitize(renderedHtml, { ALLOWED_TAGS });
                },
            },
        });

        mde.codemirror.options.extraKeys["Tab"] = false;
        mde.codemirror.options.extraKeys["Shift-Tab"] = false;

        $(this).data("easymde", mde);
        this.EasyMDE = mde;
        this.CodeMirror = mde.codemirror;
    });
});
