/* FIXME: BS5 */
import "jquery";
import { debounce } from "throttle-debounce";

import { Pagination } from "~/types";

import { queryStringToObject } from "../query_parser";
import { Route } from "../router/routes";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import _active from "./_active";

type TemplateExtras = { _even: boolean; _odd: boolean; _index: number };

type HasId = { id: string };

type Actions<T = HasId> = {
    [action_name: string]: {
        click?: (this: Index<T>, event: JQuery.ClickEvent) => void;
        change?: (this: Index<T>, event: JQuery.ChangeEvent) => void;
        focus?: (this: Index<T>, event: JQuery.FocusEvent) => void;
        blur?: (this: Index<T>, event: JQuery.BlurEvent) => void;
        select?: (this: Index<T>, event: JQuery.SelectEvent) => void;

        [event_name: string]: (this: Index<T>, e: JQuery.Event) => void;
    };
};

type Options<T = HasId, Id = string> = {
    actions: Actions<T>;
    filters: { [key: string]: ($element: JQuery<HTMLInputElement>) => void };
    extraData: { [key: string]: unknown };

    getId: (item: T) => Id;
    endpoint: Route;
    template?: string | ((this: Index<T>, data: T & TemplateExtras) => string);
    reactTemplate?: (data: TemplateExtras & T, target: DocumentFragment) => void;

    element: string;
    filter_element: string;
    actions_element: string;

    resultClicked?: (item: T, row: JQuery<HTMLElement>) => void;
};

class Index<T = HasId> {
    static DEFAULTS: Options<HasId, string> = {
        actions: {},
        filters: {},
        extraData: {},

        getId: (item: HasId) => item.id,
        element: "#index",
        template: "index_template",

        endpoint: null,

        filter_element: "#filters",
        actions_element: "#actions",
    };

    more: boolean;
    total: number;
    page: number;

    sort_by: string;
    sort_dir: string;

    options: Options<T>;

    template?: (this: Index<T>, data: TemplateExtras & T) => string;
    reactTemplate?: (data: TemplateExtras & T, target: DocumentFragment) => void;

    elements: {
        element: JQuery;
        thead: JQuery;
        tbody: JQuery;
        tfoot: JQuery;
        filter: JQuery;
        select_all: JQuery;
        actions: JQuery;
    };
    results: { [key: string]: [T, JQuery<HTMLElement>] };
    filters: { [element_name: string]: JQuery };
    actions: Actions<T>;

    static newInstance<T extends HasId>(options: Partial<Options<T>>): Index<T> {
        return new Index(options);
    }

    constructor(options: Partial<Options<T>>) {
        this.more = false;
        this.total = 0;
        this.page = 0;
        this.template = null;
        this.elements = null;
        this.results = {};
        this.filters = {};
        this.actions = {};

        this.options = $.extend({}, Index.DEFAULTS, options);

        for (const o in this.options) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (Object.prototype.hasOwnProperty.call(this.options, o) && this.options[o] === null) {
                throw new Error("Index options require " + o + " to be not null");
            }
        }

        this.init();
    }

    init() {
        _active.index = this;

        if (this.options.reactTemplate) {
            this.reactTemplate = this.options.reactTemplate;
        } else if (this.options.template) {
            this.template =
                typeof this.options.template === "function"
                    ? this.options.template
                    : tmpl(this.options.template);
        }

        this.elements = {
            element: $(this.options.element as string),
            thead: $(this.options.element as string).find("thead"),
            tbody: $(this.options.element as string).find("tbody"),
            tfoot: $(this.options.element as string).find("tfoot"),
            filter: $(this.options.filter_element as string),
            select_all: $("#select_all"),
            actions: $(this.options.actions_element as string),
        };

        if (this.elements.tbody.length === 0) {
            this.elements.tbody = $(document.createElement("tbody"));
            this.elements.element.append(this.elements.tbody);
        }

        if (this.elements.tfoot.length === 0) {
            this.elements.tfoot = $(document.createElement("tfoot"));
            this.elements.element.append(this.elements.tfoot);
        }

        this.elements.select_all.on("click", (e: JQuery.ClickEvent<HTMLInputElement>) =>
            this.select_all(e),
        );

        this.filters = {};
        this.elements.filter
            .find(":input")
            .each((_, element) => this._init_filter(element as HTMLInputElement));
        this.elements.actions.find(":input").each((_, element) => this._init_action(element));

        $(window)
            .on("scroll touchend resize", () => this._scroll())
            .on("popstate", () => this._popstate());

        this._clicked_check();

        this._parse_params();
        this._init_sort();
        this.clear();
        this.load();
    }

    _init_filter(elem: HTMLInputElement) {
        const $elem = $(elem);
        const debounced = debounce(400, false, () => this._trigger_filter());
        let init_filter;

        this.filters[elem.name] = $elem;

        if ((init_filter = this.options.filters[elem.name])) {
            init_filter($elem);
        }

        $elem.on("keyup", debounced);
        $elem.on("change", () => {
            debounced.cancel();
            this._trigger_filter();
        });
    }

    _init_action(elem: Element) {
        const $elem = $(elem),
            action_name = $(elem).data("action"),
            action = this.options.actions[action_name];

        if (action) {
            for (const event in action) {
                if (Object.prototype.hasOwnProperty.call(action, event)) {
                    $elem.on(event, action[event].bind(this));
                }
            }
        }
    }

    _init_sort() {
        const cols = this.elements.thead.find(".sort-column"),
            col = cols.filter("[data-sort]");

        cols.on("click", this._trigger_sort.bind(this));

        this.sort_by = col.data("column");
        this.sort_dir = col.data("sort");
    }

    _trigger_filter() {
        this.page = 0;

        window.history.pushState(null, null, "#" + $.param(this.filter_val(), true));

        this.delayed_load(true);
    }

    _popstate() {
        this.page = 0;

        this._parse_params();
        this.load(true);
    }

    _parse_params() {
        const data = queryStringToObject(document.location.hash.substr(1));
        let s2, elem;

        for (const i in data) {
            if (Object.prototype.hasOwnProperty.call(data, i) && (elem = this.filters[i])) {
                if ((s2 = elem.data("select2"))) {
                    if (s2.opts.multiple && !(data[i] instanceof Array)) {
                        elem.select2("val", [data[i]]);
                    } else {
                        elem.select2("val", data[i]);
                    }
                } else {
                    elem.val(data[i]);
                }
            }
        }
    }

    _trigger_sort(e: JQuery.ClickEvent<HTMLTableCellElement>) {
        this.page = 0;

        this.elements.thead.find(".sort-column").each((_, elem) => {
            if (e.target !== elem) {
                elem.setAttribute("data-sort", "");
            }
        });

        this.sort_by = e.target.getAttribute("data-column");
        this.sort_dir = e.target.getAttribute("data-sort") === "desc" ? "asc" : "desc";
        e.target.setAttribute("data-sort", this.sort_dir);

        this.delayed_load(true);
    }

    filter_data() {
        const data = this.filter_val();

        data.page = (this.page || 0) + 1;
        data.sort_by = this.sort_by;
        data.sort_dir = this.sort_dir;

        return data;
    }

    filter_val(): { [key: string]: number | string | string[] | boolean } {
        const data: { [key: string]: number | string | string[] | boolean } = {};

        $.each(this.filters, (i, e) => {
            if (e.data("select2") && e.select2("val")) {
                data[i] = e.select2("val");
            } else if (e.val()) {
                if (e.attr("type") === "checkbox") {
                    data[i] = e.is(":checked");
                } else {
                    data[i] = e.val();
                }
            }
        });

        return data;
    }

    has_selected() {
        return this.elements.tbody.find(":checked").length;
    }

    count_results() {
        return this.elements.tbody.find(":checkbox").length;
    }

    selected_data() {
        return this.elements.tbody
            .find(":checked")
            .map((_, e: HTMLInputElement) => e.value)
            .get();
    }

    selected_results() {
        const ids = this.selected_data(),
            results = [];

        for (let i = 0; i < ids.length; i++) {
            const result = this.results[ids[i]];

            if (result) {
                results.push(result[0]);
            }
        }

        return results;
    }

    load(clearFirst = false) {
        const data = {
            ...this.filter_data(),
            ...this.options.extraData,
        };

        const url = router.route(this.options.endpoint as Route, data);

        if (this.xhr) {
            if (this.loading_url === url) {
                return this.xhr;
            }

            this.xhr.abort();
        }

        this.clear_before_render = clearFirst;
        this.render_message("Loading...");

        this.loading_url = url;
        return (this.xhr = $.getJSON(url))
            .done(this.handle_response.bind(this))
            .fail(this.load_failed.bind(this));
    }

    load_next_page() {
        if (this.more) {
            this.load(false);
        }
    }

    xhr?: JQuery.jqXHR;
    loading_url?: string;
    clear_before_render?: boolean;

    timeout?: number;

    delayed_load(clearFirst = false) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = window.setTimeout(() => {
            this.load(clearFirst);
        }, 300);
    }

    handle_response(data: Pagination<T>) {
        this.xhr = null;
        this.loading_url = null;

        this.more = data.more;
        this.total = data.total;
        this.page = data.page;

        this.render(data.results);
        this._scroll();
    }

    load_failed(_xhr: JQuery.jqXHR, status: string) {
        this.xhr = null;
        this.loading_url = null;

        if (status !== "abort") {
            this.render_message("Failed to load more data");
        }
    }

    private callTemplate(i: number, result: T) {
        return this.template({
            _even: !(i % 2),
            _odd: !!(i % 2),
            _index: i,
            ...result,
        });
    }

    render(results: T[]) {
        if (this.clear_before_render) {
            this.clear();
        }

        for (let i = 0; i < results.length; i++) {
            if (this.reactTemplate) {
                const fragment = document.createDocumentFragment();

                this.reactTemplate(
                    {
                        _even: !(i % 2),
                        _odd: !!(i % 2),
                        _index: i,
                        ...results[i],
                    },
                    fragment,
                );

                this.results[this.options.getId(results[i])] = [
                    results[i],
                    this.elements.tbody.append(fragment),
                ];
            } else {
                const row = $(
                    $.parseHTML(this.callTemplate(i, results[i])) as unknown as HTMLElement,
                );
                if (this.options.resultClicked) {
                    row.on("click", () => {
                        this.options.resultClicked(results[i], row);
                    });
                }

                this.results[this.options.getId(results[i])] = [results[i], row];

                this.elements.tbody.append(row);
            }
        }

        this.render_message(this.more ? "Scroll to load more" : "No more pages to load");

        this.elements.tbody.find(":checkbox").on("change", this._clicked_check.bind(this));
        this.elements.tbody.find("[data-action]").each((_, elem) => this._init_action(elem));

        this._clicked_check();
    }

    messageClicked() {
        if (this.more) {
            this.load_next_page();
        }
    }

    replace(results: Array<T>) {
        for (let i = 0; i < results.length; i++) {
            const current = this.results[this.options.getId(results[i])];

            if (current) {
                const oldRow = current[1];
                const isChecked = oldRow.find(":checkbox").is(":checked");

                current[0] = results[i];
                current[1] = $(
                    $.parseHTML(this.callTemplate(i, results[i])) as unknown as HTMLElement,
                );
                if (this.options.resultClicked) {
                    current[1].on("click", () => {
                        this.options.resultClicked(results[i], current[1]);
                    });
                }

                //If the template returns multiple rows, this causes some crazy duplication issues
                // Because of this, we need to specifically insert the new data AFTER the old data
                // then remove the old elements. This should work for nearly any use case.
                oldRow.last().after(current[1]);
                oldRow.remove();

                current[1]
                    .css("background-color", "#3CDE00")
                    .animate({ backgroundColor: "#FFFFFF" });
                current[1]
                    .find(":checkbox")
                    .on("change", this._clicked_check.bind(this))
                    .prop("checked", isChecked);
                current[1].find("[data-action]").each((_, elem) => this._init_action(elem));
            }
        }

        this._clicked_check();
    }

    render_message(message: string) {
        const colSpan = this.elements.thead
            .find("th")
            .get()
            .map((e) => e.colSpan || 1)
            .reduce((a, b) => a + b, 0);

        this.elements.tfoot
            .html(
                `
                <tr>
                    <td class='text-center text-muted' colspan=${colSpan}>
                    ${message}
                    </td>
                </tr>
                `,
            )
            .on("click", this.messageClicked.bind(this));
    }

    clear() {
        this.elements.tbody.html("");
        this.elements.tfoot.html("");
    }

    select_all(e: JQuery.ClickEvent<HTMLInputElement>) {
        e.target.indeterminate = false;

        this.elements.tbody.find(":checkbox").prop("checked", e.target.checked);
        this._clicked_check();
    }

    _clicked_check() {
        this.elements.actions.find(":input").prop("disabled", !this.has_selected());

        if (this.count_results() === this.has_selected()) {
            this.elements.select_all.prop("checked", true).prop("indeterminate", false);
        } else if (this.has_selected()) {
            this.elements.select_all.prop("checked", false).prop("indeterminate", true);
        } else {
            this.elements.select_all.prop("checked", false).prop("indeterminate", false);
        }
    }

    _scroll() {
        const $w = $(window),
            $d = $(document),
            winHeight = window.innerHeight,
            docHeight = document.body.scrollHeight;

        if (
            winHeight + window.scrollY > docHeight - 20 || //For mobile browsers with zooming
            $w.scrollTop() + $w.height() > $d.height() - 50
        ) {
            //For normal desktop browsers
            this.load_next_page();
        }
    }
}

export default Index;
