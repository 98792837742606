/* FIXME: BS5 */
$(function () {
    $("[data-attachment]").each(function () {
        const $this = $(this),
            $filename = $this.find(".filename"),
            $hidden = $this.find("input[type=hidden]"),
            $fileinput = $this.find("input[type=file]"),
            $delete = $this.find("button[data-delete]");

        function handleResize() {
            $filename.css("width", $this.find(".form-control").width() + "px");
        }

        $(window).on("resize", handleResize).trigger("resize");
        $fileinput
            .on("change", function (e) {
                if (e.target.files.length) {
                    $filename.text(e.target.files[0].name);
                    $hidden.val("").prop("disabled", true).prop("readonly", true);
                    $delete.show();
                } else if ($hidden.val() && $hidden.val() !== "_delete") {
                    $filename.text($hidden.val());
                    $hidden.prop("disabled", false).prop("readonly", false);
                    $delete.show();
                } else {
                    $filename.text("");
                    $hidden.val("_delete").prop("disabled", false).prop("readonly", false);
                    $delete.hide();
                }

                handleResize();
            })
            .trigger("change");

        $delete.on("click", function () {
            if (window.confirm("Delete the attached file?")) {
                $hidden.val("_delete");
                $fileinput.val("").trigger("change");
            }
        });
    });
});
