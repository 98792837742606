/* FIXME: BS5 */
export interface QueryHash {
    [key: string]: string | string[];
}

export function queryStringToObject(query: string, initialObject?: QueryHash): QueryHash {
    const query_string: QueryHash = initialObject || {};
    if (!query) {
        return query_string;
    }

    const vars = (query.startsWith("?") ? query.substr(1) : query).split("&");

    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (!pair[0]) {
            continue;
        }

        pair[0] = decodeURIComponent(pair[0]);
        pair[1] = decodeURIComponent(pair[1]).replace(/\+/g, " ");

        if (typeof query_string[pair[0]] === "undefined") {
            query_string[pair[0]] = pair[1];
        } else if (typeof query_string[pair[0]] === "string") {
            query_string[pair[0]] = [query_string[pair[0]] as string, pair[1]];
        } else {
            (query_string[pair[0]] as string[]).push(pair[1]);
        }
    }

    return query_string;
}

export function objectToQueryString(params: QueryHash): string {
    return Object.entries(params)
        .reduce((result, [key, value]) => {
            if (value instanceof Array) {
                value.forEach((v) =>
                    result.push(`${encodeURIComponent(key)}=${encodeURIComponent(v)}`),
                );
            } else {
                result.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
            return result;
        }, [])
        .join("&");
}
