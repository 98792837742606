/* eslint-disable simple-import-sort/sort */
import { enableMapSet } from "immer";
enableMapSet();

// And the rest of comanage
import "./js/router";
import "./js/comanage";
import "./js/applyTo";
import "./js/controllers";
import "./js/cors_transport";
import "./js/datetime";
import "./js/droppable";
import "./js/fieldset";
import "./js/formatting";
import "./js/microtemplating";
import "./js/polyfills";
import "./js/select2";
import "./js/trackTransitions";
import "./js/timeline";
import "./js/universalsearch";
import "./js/sidebar";
import "./scss/comanage.scss";
