/* FIXME: BS5 */
(function (select2) {
    const template = tmpl`Batch <%=code%>`;

    select2.receivable_invoice_batch = {
        allowClear: true,
        closeOnSelect: false,
        minimumInputLength: 0,
        ajax: {
            url: () => router.route("api.internal.receivable.invoice.batches"),
            dataType: "json",
            quietMillis: 100,
            data: (term, page) => ({ term, page }),
            results: (data) => data,
        },
        formatResult(batch) {
            if (this.processResult) {
                this.processResult(batch);
            }
            return template(batch);
        },
        formatSelection(batch) {
            if (this.processSelection) {
                this.processSelection(batch);
            }
            return template(batch);
        },
        initSelection: select2.makeInitSelection((args) =>
            router.route("api.internal.receivable.invoice.batch", args),
        ),
    };
    select2.receivable_invoice_batch_multiple = $.extend(
        { multiple: true },
        select2.receivable_invoice_batch,
    );
})(window.comanage.select2);
