/* FIXME: BS5 */
/* eslint-disable no-extend-native */

import "core-js/stable";
import "promise-polyfill/src/polyfill";
import "regenerator-runtime/runtime";

declare global {
    interface Array<T> {
        englishJoin(max?: number): string;
    }

    interface String {
        truncate(length: number): string;

        toTitleCase(): string;
    }
}

String.prototype.truncate = function (l: number) {
    if (this.length <= l) {
        return this.valueOf();
    }
    return this.valueOf().substr(0, l).replace(/\w+$/, "") + "…";
};

String.prototype.toTitleCase = function () {
    return this.replace(/\w\S*/g, function (txt: string) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

Array.prototype.englishJoin = function (max) {
    if (this.length <= 2) {
        return this.join(" and ");
    }
    if (max && this.length > max) {
        return this.slice(0, max).join(", ") + ", and " + (this.length - max) + " more";
    }
    return this.slice(0, this.length - 1).join(", ") + ", and " + this[this.length - 1];
};

if (NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype
        .forEach as unknown as typeof NodeList.prototype.forEach;
}
