/* FIXME: BS5 */
(function (select2) {
    const formatOccupancy = (occupancy) =>
        `${occupancy.tenants
            .map((at) => at.tenant.name)
            .englishJoin()
            .truncate(100)} to ${occupancy.property.name}`;

    select2.occupancy = {
        allowClear: true,
        closeOnSelect: false,
        minimumInputLength: 0,
        ajax: {
            url: () => router.route("api.internal.occupancies"),
            dataType: "json",
            quietMillis: 100,
            data: (term, page) => ({ term, page }),
            results: (data) => data,
        },
        formatResult: function (occupancy) {
            if (this.processResult) {
                this.processResult(occupancy);
            }
            return formatOccupancy(occupancy);
        },
        formatSelection: function (occupancy) {
            if (this.processSelection) {
                this.processSelection(occupancy);
            }
            return formatOccupancy(occupancy);
        },
        initSelection: select2.makeInitSelection((args) =>
            router.route("api.internal.occupancy", args),
        ),
    };

    select2.occupancy_multiple = $.extend({ multiple: true }, select2.occupancy);
})(window.comanage.select2);
