jQuery.fn.addressform = function () {
    const mailing_selector = this.data("mailing-target"),
        billing_selector = this.data("billing-target"),
        checkbox_selector = this.data("checkbox-target"),
        mailing = this.find(mailing_selector),
        billing = this.find(billing_selector),
        checkbox = this.find(checkbox_selector);

    function toggleBillingForm() {
        if (checkbox.is(":checked")) {
            billing
                .find(":input")
                .attr("disabled", "disabled")
                .each(function (_: number, field: HTMLElement) {
                    cloneAddressForm(field);
                });
        } else {
            billing.find(":input").removeAttr("disabled");
        }
    }

    function cloneAddressForm(field: HTMLElement) {
        const id = field.id,
            field_name = id.split("-")[1],
            billing_field = billing.find(":input[name$=" + field_name + "]"),
            mailing_field = mailing.find(":input[name$=" + field_name + "]");
        billing_field.val(mailing_field.val());
    }

    mailing.find(":input").keyup(function () {
        if (checkbox.is(":checked")) {
            cloneAddressForm(this);
        }
    });

    checkbox.change(function () {
        toggleBillingForm();
    });

    toggleBillingForm();

    return this;
};
