/* FIXME: BS5 */
(function (select2) {
    select2.property = {
        allowClear: true,
        closeOnSelect: false,
        minimumInputLength: 0,
        ajax: {
            url: () => router.route("api.internal.properties"),
            dataType: "json",
            quietMillis: 100,
            data: (term, page) => ({ term, page }),
            results: function (data) {
                if (!this.includeUnitsInResults) {
                    return data;
                }
                return {
                    ...data,
                    results: data.results.map((property) =>
                        property.type === "SingleProperty"
                            ? property
                            : {
                                  ...property,
                                  children: property.units.map((unit) => ({
                                      ...unit,
                                      isUnit: true,
                                      unitId: unit.id,
                                      id: property.id,
                                      property: property,
                                  })),
                              },
                    ),
                };
            },
            includeUnitsInResults: false,
        },
        formatResult: function (property) {
            if (this.processResult) {
                this.processResult(property);
            }
            return `<strong>${property.name}</strong> ${Formatters.address_short(property)}`;
        },
        formatSelection: function (property) {
            if (this.processSelection) {
                this.processSelection(property);
            }
            return `${property.name} - ${Formatters.address_short(property)}`;
        },
        initSelection: select2.makeInitSelection((args) =>
            router.route("api.internal.property", args),
        ),
    };

    select2.property_multiple = $.extend({ multiple: true }, select2.property);
})(window.comanage.select2);
