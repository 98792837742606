/* FIXME: BS5 */
$(function () {
    const $event_timeline = $(".event-timeline"),
        $new_event = $event_timeline.find(".new-event"),
        $form = $new_event.find("form"),
        $private = $form.find("#note-private-private"),
        $public = $form.find("#note-private-public"),
        $comment = $form.find("[name=comment]"),
        $inputs = $form.find(":input"),
        $subscriptions = $("#notification-subscriptions"),
        $subscription_buttons = $subscriptions.find("button"),
        $subscribe = $subscriptions.find("#notification-subscriptions--subscribe"),
        $unsubscribe = $subscriptions.find("#notification-subscriptions--unsubscribe");
    /* timeline manipulation */
    function makeNewEvent(contents) {
        return $(
            "" +
                '<div class="event event-comment">' +
                '<i class="event-icon"></i>' +
                contents +
                "</div>",
        );
    }

    $comment.focus(function () {
        $(".event-timeline .new-event").addClass("not-empty");
    });

    $(".event-timeline .new-event form").submit(function (e) {
        e.preventDefault();

        const formData = $form.serializeObject();
        console.log(formData);

        $.ajax({
            type: "POST",
            url: $form.data("action"),
            data: JSON.stringify(formData),
            contentType: "application/json",
            dataType: "json",
        })
            .done(function (data) {
                // Push the new event onto the timeline
                makeNewEvent(data.html)
                    .insertAfter($new_event)
                    .addClass("expandable")
                    .addClass("open")
                    .find(".timeago")
                    .timeago();

                $private.prop("checked", false);
                $public.prop("checked", true);
                $comment.val("");
            })
            .fail(function () {
                alert("Failed to post comment!");
            })
            .always(function () {
                $new_event.removeClass("not-empty");
                $inputs.prop("disabled", false).blur();
            });
    });

    /* Subscriptions Manipulation */
    $subscription_buttons.on("click", function () {
        const $this = $(this);

        $subscription_buttons.prop("disabled", true);

        $.ajax({
            type: $this.data("method"),
            url: $this.data("action"),
            dataType: "json",
        })
            .done(function (subscription) {
                const id = "notification-subscription--" + subscription.id,
                    $current = $subscriptions.find("#" + id);

                if (subscription.state === "active") {
                    if ($current.length) {
                        $current.text(subscription.membership_name);
                    } else {
                        $subscriptions.append(
                            '<dd id="' + id + '">' + subscription.membership_name + "</dd>",
                        );
                    }
                } else {
                    $current.remove();
                }

                $subscribe.toggleClass("d-none", subscription.state === "active");
                $unsubscribe.toggleClass("d-none", subscription.state !== "active");
            })
            .always(function () {
                $subscription_buttons.prop("disabled", false);
            });
    });
});
