/* FIXME: BS5 */
import _active from "./_active";
import Attachment from "./attachment";
import Index from "./indexComponent";
import Note from "./notes";

const controllers = {
    _active,
    Attachment,
    Index,
    Note,
};

window["comanage"] = window["comanage"] || {};
window["comanage"]["controllers"] = controllers;

export default controllers;
