/* FIXME: BS5 */
(function () {
    const d = document,
        s = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65],
        l = s.length;
    let i = 0,
        v;
    window.addEventListener("keydown", function (e) {
        if ((v = e.which) !== s[i]) {
            i = 0;
        }
        if (v === s[i]) {
            i++;
            if (i === l) {
                s.length = 0;

                v = d.createElement("style");
                v.textContent = `@keyframes lol { from { background-position: 0% 0% } to { background-position: 100% 0% } }`;
                d.body.appendChild(v);

                v = d.createElement("div");
                $.extend(v.style, {
                    backgroundImage: "URL(/static/img/icon16.png)",
                    backgroundPositionX: "8px",
                    position: "fixed",
                    bottom: "0",
                    left: "0",
                    height: "16px",
                    width: "100%",
                    animation: "lol 30s linear infinite",
                });

                d.body.appendChild(v);
            }
        }
    });
})();
