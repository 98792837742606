/* FIXME: BS5 */
/** Helpers for javascript formatting of different comanage types **/

function formatCurrency(v: number) {
    return v.toLocaleString("en-US", { style: "currency", currency: "USD" });
}

const Formatters = {
    datetime: tmpl`<time class="timeago" datetime="<%= __obj %>"><%= __obj %></time>`,

    /* Formats a phone object, expects an object like:
     * {phone_number: "+15204197603",
     *  phone_type: "Mobile",
     *  extension: "1234",
     *  note: "Only call between 8am-5pm MST"}
     */
    format_phone_number(phone_number: string) {
        return phone_number.match(/\+1\d{10}/)
            ? phone_number.replace(/\+(\d+)(\d{3})(\d{3})(\d{4})/, "($2) $3-$4")
            : phone_number;
    },

    phone_number: tmpl`
    <i class="fa fa-phone fa-fw"></i>
    <% if (phone_type) { %><%= phone_type %><% } %>
    <%= $f.format_phone_number(phone_number) %>
    <% if (extension) { %>, Ext. <%= extension %><% } %>
    <% if (note) { %> (<%= note %>)<% } %>
  `,

    /* Formats an email object, expects
     * {email: "franklyn@tackitt.net",
     *  note: "Personal Business"}
     */
    email_address: tmpl`
    <i class="fa fa-envelope fa-fw"></i>
    <%= email %>
    <% if (note) { %>
      (<%= note %>)
    <% } %>
  `,

    /* Formats an address object, expects
     * {address: "2101 N Country Club",
     *  unit: "Ste 103",
     *  postal: "85716",
     *  city: "Tucson",
     *  state: "AZ",
     *  country: "US"}
     */
    address: tmpl`
    <address>
    <%= address %>
    <% if (unit) { %>, <%= unit %><% } %><br>
    <%= city %>, <%= state %> <%= postal %>
    <% if (country && country !== "US") { %><br><%= country_name %><% } %>
    </address>
  `,
    address_short: tmpl`
    <%= address %>
    <% if (unit && unit.trim()) { %>, <%= unit %><% } %>
    <small class="text-muted"><%= city %>, <%= state %> <%= postal %></small>
  `,
    address_plain: tmpl`
    <%= address %>
    <% if (unit && unit.trim()) { %>, <%= unit %><% } %>
    <%= city %>, <%= state %> <%= postal %>
  `,

    /* Formats a property object, expects
     * {name: "pm2101",
     *  address: "2101 N Country Club",
     *  unit: "Ste 103",
     *  postal: "85716",
     *  city: "Tucson",
     *  state: "AZ"}
     */
    property: tmpl`
    <address>
    <strong><% if (parent) { %><%=parent.name%> - <% } %><%= name %></strong>
    <%= address %>
    <% if (unit) { %>, <%= unit %><% } %><br>
    <%= city %>, <%= state %> <%= postal %>
    </address>
  `,
    property_short: tmpl`
    <strong><% if (parent) { %><%=parent.name%> - <% } %><%= name %></strong>
    <%= address %>
    <% if (unit) { %>, <%= unit %><% } %>
    <small class="text-muted"><%= city %>, <%= state %> <%= postal %></small>
  `,
    property_mini: tmpl`
    <strong><% if (parent) { %><%=parent.name%> - <% } %><%= name %></strong>
    <%= address %>
    <% if (unit) { %>, <%= unit %><% } %>
  `,

    bank_deposit: tmpl`
    <strong><%= deposit_number %> <%= description %><strong> <% if (canceled) { %> (CANCELED) <% } %>
  `,

    financial_account: tmpl`
    <%= type %> <%= name %>
  `,

    occupancy: tmpl`
    <%= $f.tenant(tenants[0].tenant) %> - <%= $f.property_mini(property) %>
  `,

    receivable_receipt: tmpl`
    <%= $f.$(amount) %> <%= reference %>
  `,

    /* Formats a category object, expects
     * {category: "Home Remodels & Additions",
     *  parent: "Additions & Remodels"}
     */
    category: tmpl`<%= category %>`,

    contact: tmpl`<%= type %> - <%= name %>`,
    contact_short: tmpl`<%= name %>`,
    contact_result: tmpl`<%= name %> <span class="text-muted font-small pull-right"><%= type %></span>`,

    listing: tmpl`<%= name %> (<%= vendor_type %>)`,
    listing_long: tmpl`<%= name %> (<%= vendor_type %>) <span class="pull-right text-muted font-small"><%= categories.englishJoin(1) %></span>`,
    listing_short: tmpl`<%= name %>`,

    tenant: tmpl`<%= name %>`,
    tenant_short: tmpl`<%= name %>`,

    /* Formats a contact object, expects
     * {id: "4ae87cfd-6a72-46e3-a4e4-fcbd935ac6df",
     *  company_name: "Frank Properties",
     *  first_name: "Frank",
     *  last_name: "Tackitt",
     *  type: "Owner"}
     */

    currencyf: formatCurrency,
    $f: formatCurrency,

    currency: (v: number) => formatCurrency(v / 100),
    $: (v: number) => formatCurrency(v / 100),
};

declare global {
    interface Window {
        Formatters: typeof Formatters;
        $f: typeof Formatters;
    }
}

window["Formatters"] = window["$f"] = Formatters;

export default Formatters;
