/* FIXME: BS5 */
(function (select2) {
    // ACH return codes, from comanage_nacha.return_codes
    select2.return_codes = {
        R01: "Insufficient funds in receiver's account (debit transactions only)",
        R02: "Receiver's account is closed",
        R03: "No account on file (RDFI is unable to locate account); not used for ARC, BOC, or POP item without individual name field",
        R04: "Invalid account number",
        R05: "Unauthorized debit to consumer account (SEC code CCD, CTX, IAT)",
        R06: "Returned at Wells Fargo's request",
        R07: "ACH authorization has been revoked by the receiver (adjustment entry)",
        R08: "Payment on this item has been stopped",
        R09: "Insufficient collected funds in the account being charged (uncollected)",
        R10: "Customer advises not authorized (SEC consumer codes except ARC, BOC, IAT, POP); notice not provided, improper source document, amount not accurately obtained from source document",
        R11: "Check truncation return (specify)",
        R12: "Account sold to another financial institution",
        R14: "Representative payee deceased or cannot continue in that capacity",
        R15: "Beneficiary or account holder other than representative payee deceased",
        R16: "Account funds frozen or item returned at OFAC request",
        R17: "Item returned because of invalid data; refer to addenda for information",
        R20: "Account does not allow ACH transactions or limit for transactions has been exceeded",
        R21: "Invalid company identification (SEC code CIE)",
        R22: "Invalid individual ID (SEC code CIE, MTE)",
        R23: "Credit entry refused by receiver",
        R24: "Duplicate entry",
        R29: "Corporate customer advises not authorized",
        R31: "Permissible return entry (SEC code CCD, CTX)",
        R33: "Return of item (SEC code XCK)",
        R37: "Source document presented for payment (SEC code ARC, BOC, POP)",
        R38: "Stop payment on source document (SEC code ARC, BOC)",
        R39: "Improper source document (SEC code ARC, BOC, POP)",
        R40: "Return of item by government agency (SEC code ENR)",
        R41: "Invalid transaction code (SEC code ENR)",
        R42: "Routing/transit number check digit error (SEC code of ENR)",
        R43: "Invalid account number (SEC code of ENR)",
        R44: "Invalid individual ID (SEC code ENR)",
        R45: "Invalid individual name or company name (SEC code ENR)",
        R46: "Invalid representative payee indicator code (SEC code ENR)",
        R47: "Duplicate enrollment (SEC code ENR)",
        R50: "State law affecting RCK acceptance (SEC code RCK)",
        R51: "Item is ineligible, notice not provided, signature not genuine, or original item altered for adjustment entry (SEC code RCK)",
        R52: "Stop payment on item (SEC code RCK)",
        R53: "Item and ACH entry presented for payment (SEC code RCK)",
        R80: "IAT entry coding error (SEC code IAT)",
        R81: "Non-participant in IAT program (SEC code IAT)",
        R82: "Invalid foreign RDFI identification (SEC code IAT)",
        R83: "Foreign RDFI unable to settle (SEC code IAT)",
        R84: "IAT entry not processed by gateway operator (SEC code IAT)",
        R85: "Incorrectly coded outbound international payment (SEC code IAT)",
        R94: "Check Conversion Administrative Return item was processed and resubmitted as a photocopy",
        R95: "Check Conversion Administrative Return item was processed as MICR-Split and resubmitted as a PAC (pre-authorized check)",
        R97: "Check Conversion Administrative Return item was processed and resubmitted with corrected dollar amount",
        R98: "Indicates a returned PAC (pre-authorized check); RDFI provided a text reason and indicated a new account number on the PAC itself",
        R99: "Indicates a returned PAC (pre-authorized check); RDFI provided a text reason on the PAC itself for which there is no equivalent ACH return reason code",
        // Return reason codes used by ACH operators
        R13: "Invalid ACH routing number",
        R18: "Improper effective date",
        R19: "Amount error: a prenote's amount is not zero, or a non-prenote item's amount is zero; amount is greater than $25,000 (SEC code ARC, BOC, POP)",
        R25: "Addenda record error",
        R26: "Mandatory field error",
        R27: "Trace number error",
        R28: "Routing/transit number check digit error",
        R30: "RDFI not participant in check truncation program",
        R32: "RDFI non-settlement",
        R34: "Limited participation ODFI",
        R35: "Return of improper debit entry",
        R36: "Return of improper credit entry",
        // Dishonored return reason codes
        R61: "Misrouted return - RDFI for original entry has placed incorrect routing/transit number in RDFI identification field",
        R62: "Return of erroneous or reversing debit",
        R67: "Duplicate return - Wells Fargo received more than one return for an entry",
        R68: "Untimely return - return was not sent within established timeframe",
        R69:
            "Field errors. " +
            "One or more of the following codes is included in the addenda information field to indicate the type of incorrect information in the return; for multiple errors, codes are separated by an asterisk (01*03, for example)\n" +
            "    01 Incorrect RDFI account - account number for return is different than original entry\n" +
            "    02 Incorrect trace - trace number for return is different than original entry\n" +
            "    03 Incorrect amount - amount for return is different than original entry\n" +
            "    04 Incorrect individual ID - individual ID for return is different than original entry\n" +
            "    05 Incorrect transaction code - transaction code for return is different than original entry\n" +
            "    06 Incorrect company ID - company ID for return is different than original entry\n" +
            "    07 Incorrect effective date - effective date in return is different than original entry",
        R70: "Permissible return entry not accepted / not requested by ODFI",
        C96: "Check Conversion Administrative Return dishonor (dollar amount will be zero)",
        // Contested/corrected dishonored return reason codes
        R71: "Misrouted dishonored return - Wells Fargo placed incorrect routing/transit number in the RDFI identification field",
        R72: "Untimely return - dishonored return not sent within established timeframe",
        R73: "Timely original return - RDFI certifies that original return entry was sent within established timeframe for original returns",
        R74: "Corrected return - RDFI is correcting a previous return entry that was dishonored because it contained incomplete or incorrect information",
        R75: "Original return not a duplicate",
        R76: "No errors found",
        R77: "Non-acceptance of R62 dishonored return",
        // Notification of change (NOC) reason codes
        C01: "Incorrect account number",
        C02: "Incorrect routing/transit number",
        C03: "Incorrect routing/transit number and incorrect account number",
        C05: "Incorrect transaction code",
        C06: "Incorrect account number and incorrect transaction code",
        C07: "Incorrect routing/transit number, account number, and transaction code",
        C08: "Incorrect foreign RDFI identification (SEC code IAT)",
        C09: "Incorrect individual ID or receiver ID",
        C13: "Addenda format error",
        C14: "Incorrect SEC code for outbound international ACH transaction",
        C99: "Converted to MICR draft (check conversion items)",
        // Refused notification of change reason codes
        C61: "Misrouted NOC",
        C62: "Incorrect trace number",
        C63: "Incorrect company ID",
        C64: "Incorrect individual ID",
        C65: "Incorrectly formatted corrected data",
        C66: "Incorrect discretionary data",
        C67: "Routing/transit number not from original entry",
        C68: "Account number not from original entry",
        C69: "Incorrect transaction code",
    };
})(window.comanage.select2);
