/* FIXME: BS5 */
(function (select2) {
    const template = tmpl`Batch <%=code%>`;

    select2.workorder_batch = {
        allowClear: true,
        closeOnSelect: false,
        minimumInputLength: 0,
        ajax: {
            url: () => router.route("api.internal.workorder.batches"),
            dataType: "json",
            quietMillis: 100,
            data: (term, page) => ({ term, page }),
            results: (data) => data,
        },
        formatResult: function (workorder_batch) {
            if (this.processResult) {
                this.processResult(workorder_batch);
            }
            return template(workorder_batch);
        },
        formatSelection: function (workorder_batch) {
            if (this.processSelection) {
                this.processSelection(workorder_batch);
            }
            return template(workorder_batch);
        },
        initSelection: select2.makeInitSelection((args) =>
            router.route("api.internal.workorder.batch", args),
        ),
    };

    select2.workorder_batch_multiple = $.extend({ multiple: true }, select2.workorder_batch);
})(window.comanage.select2);
