/* FIXME: BS5 */
import "jquery";
import "timeago";
import "./attachment";
import "./auth";
import "./bank_account";
import "./easter_egg";
import "./easymde";
import "./image_error_fallback";
import "./jquery/fn/addressForm";
import "./jquery/fn/toggleSlide";
import "./jquery/applyTo";
import "./loader";
import "./serialize_object";

$.timeago.settings.allowFuture = true;
