/* FIXME: BS5 */
(function (_) {
    const weights = [3, 7, 1, 3, 7, 1, 3, 7];

    function calculateCheckDigit(rtNumber) {
        if (rtNumber.length !== 8) {
            throw new Error("Routing number is not 8 digits");
        }

        const checkSum = rtNumber
            .split("")
            .map(function (digit, idx) {
                return parseInt(digit) * weights[idx];
            })
            .reduce(function (a, b) {
                return a + b;
            }, 0);

        return (10 - (checkSum % 10)) % 10;
    }

    function validateCheckDigit(rtNumber, checkDigit) {
        return calculateCheckDigit(rtNumber) === parseInt(checkDigit);
    }

    function isRoutingNumberValid(rtNumber) {
        if (rtNumber.length !== 9) {
            return false;
        }

        return validateCheckDigit(rtNumber.slice(0, 8), rtNumber[8]);
    }

    _["bankAccount"] = {
        calculateCheckDigit: calculateCheckDigit,
        validateCheckDigit: validateCheckDigit,
        isRoutingNumberValid: isRoutingNumberValid,
    };
})((window["comanage"] = window["comanage"] || {}));
