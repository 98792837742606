/* FIXME: BS5 */
import "jquery";

import _active from "./_active";

const FILE_ICONS_PATH = "/static/img/icons/";

const Attachment = function (options) {
    this.options = $.extend({}, Attachment.DEFAULTS, options);

    this.elem = this.options.elem;
    this.url = router.route(this.options.endpoint, this.options.data);

    this.page = 1;

    this.init();
};

$.extend(Attachment, {
    DEFAULTS: {
        elem: null,
        endpoint: null,
        data: null,
        allow_upload: false,
        allow_edit: false,
    },

    TABLE_TEMPLATE: tmpl`<fieldset>
      <legend class="no-bottom-padding">
        Attachments
        <span class="legend-controls">
          <button type="button" name="upload_attachment" class="d-none btn btn-light upload_attachment">
            <i class="fa fa-upload"></i> Upload File
          </button>
        </span>
      </legend>
      <div class="row">
        <div class="col-full">
          <table class="table table-striped">
            <tbody class="attachments"></tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-full attachment-paginator"></div>
      </div>
    </fieldset>
  `,

    ATTACHMENT_TEMPLATE: tmpl`
    <tr class="attachment" data-attachment="<%= id %>">
      <td class="filename">
        <a href="<%= url %>" target="_blank">
          <%= name %>
        </a>
      </td>
      <td class="date">
        <%= DateTime.fromISO(created_on).toLocaleString() %>
      </td>
      <td>
        <% if (can_update) { %>
          <%= __obj.public ? 'Public' : 'Private' %>
        <% } %>
      </td>
    </tr>
  `,

    PAGINATOR_TEMPLATE: tmpl`
    <ul class="pager" style="margin-top: 2px;">
      <% if (page > 1) { %>
        <li class="previous"><a href="#">&larr; Back</a></li>
      <% } %>
      <% if (more) { %>
        <li class="next"><a href="#">&rarr; Next</a></li>
      <% } %>
    </ul>
  `,

    ATTACHMENT_MODAL_TEMPLATE: tmpl`
    <form id="attachment-upload-form" class="form-horizontal g-3" onsubmit="return false;">
      <div class="modal-header">
        <h4 class="modal-title">Upload an Attachment</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body form-horizontal g-3">
        <div class="form-group">
          <label class="col-form-left control-label">Attachment</label>
          <div class="col-form-right">
            <input type="file" class="form-control" name="attachments" id="attachments" multiple>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-light" data-bs-dismiss="modal" type="button">Cancel</button>
        <button name="submit" class="btn btn-success submit-attachment">Upload Attachment</button>
      </div>
    </form>
  `,

    NO_ATTACHMENTS: `
    <tr class="attachment no-attachments">
      <td>No attachments found</td>
    </tr>
  `,
});

Attachment["newInstance"] = function (elem) {
    const options = {
        elem: elem,
        endpoint: $(elem).data("endpoint"),
        allow_upload: $(elem).data("allow-upload") !== undefined,
        data: {
            id: $(elem).data("id"),
        },
    };

    return new Attachment(options);
};

Attachment.prototype = {
    init() {
        _active.attachment = this;
        this.initWidget();

        this.loadData();
    },

    initWidget() {
        this.elem.innerHTML = Attachment.TABLE_TEMPLATE(this);

        this.content = this.elem.querySelector(".attachments");
        this.paginator = this.elem.querySelector(".attachment-paginator");

        this.elem.querySelector(".upload_attachment").onclick = this.addAttachmentModal.bind(this);
    },

    loadData() {
        this.loading = true;

        $.getJSON(this.url, { page: this.page })
            .then((d) => {
                this.handleDataLoaded(d);
            })
            .always(() => {
                this.loading = false;
            });
    },

    handleDataLoaded(data) {
        this.attachments = data.results;
        this.page = data.page;
        this.more = data.more;
        this.total = data.total;
        this.can_post = this.options.allow_upload && data.can_post;
        this.can_put = data.can_put;

        this.updateAddAttachment();

        this.clearContent();
        this.renderContent();
        this.renderPaginator();
        this.addAttachmentHandlers();
    },

    nextPage(e) {
        if (this.more && !this.loading) {
            this.page += 1;
            this.loadData();
        }
        e.preventDefault();
    },

    previousPage(e) {
        if (this.page > 1 && !this.loading) {
            this.page--;
            this.loadData();
        }
        e.preventDefault();
    },

    updateAddAttachment() {
        const add_attachment_btn = $(this.elem.querySelector(".upload_attachment"));
        add_attachment_btn.toggleClass("d-none", !this.can_post);
    },

    clearContent() {
        this.content.innerHTML = "";
    },

    renderContent() {
        if (!this.attachments.length) {
            this.content.innerHTML = Attachment.NO_ATTACHMENTS;
        } else {
            for (let i = 0; i < this.attachments.length; i++) {
                this.content.innerHTML += Attachment.ATTACHMENT_TEMPLATE(this.attachments[i]);
            }
            $(this.content.querySelectorAll(".timeago")).timeago();
        }

        if (this.options.allow_upload) {
            $(this.content)
                .on("drop", this.handleDroppedFiles.bind(this))
                .on("dragover", this.handleDragOver.bind(this))
                .on("dragleave", this.handleDragLeave.bind(this));
        }
    },

    renderPaginator() {
        this.paginator.innerHTML = Attachment.PAGINATOR_TEMPLATE(this);

        const next = this.paginator.querySelector(".next"),
            previous = this.paginator.querySelector(".previous");

        if (next) {
            next.onclick = this.nextPage.bind(this);
        }
        if (previous) {
            previous.onclick = this.previousPage.bind(this);
        }
    },

    addAttachmentHandlers() {
        const attachments = this.content.querySelectorAll(".attachment");
        for (let i = 0; i < attachments.length; i++) {
            const attachment = attachments[i];
            $(attachment.querySelector(".delete")).on("click", this.deleteAttachment.bind(this));
        }

        this.imageErrorHandlers();
    },

    imageErrorHandlers() {
        const images = this.content.querySelectorAll("img");
        for (let i = 0; i < images.length; i++) {
            const img = images[i];
            img.onerror = this.imageLoadError.bind(this);
        }
    },

    imageLoadError(e) {
        if (e.target.src.indexOf("_blank") !== -1) {
            return;
        }

        let icon = $(e.target).data("filetype").toLowerCase();

        if (e.target.src.indexOf(FILE_ICONS_PATH) !== -1) {
            icon = "_blank";
        }

        e.target.src = `${FILE_ICONS_PATH}${icon}.png`;
    },

    insertAttachment(attachment) {
        $(this.content)
            .prepend(Attachment.ATTACHMENT_TEMPLATE(attachment))
            .find(".delete")
            .on("click", this.deleteAttachment.bind(this));

        $(this.content.querySelectorAll(".timeago")).timeago();

        this.imageErrorHandlers();
        this.removeNoAttachmentsMessage();
    },

    removeNoAttachmentsMessage() {
        const no_attachments_message = this.content.querySelector(".no-attachments");
        if (no_attachments_message) {
            no_attachments_message.remove();
        }
    },

    deleteAttachment(e) {
        $.ajax({
            type: "DELETE",
            url: this.url,
            data: { attachment_id: $(e.target).data("attachment") },
        }).then(() => {
            $(".attachment[data-attachment=" + $(e.target).data("attachment") + "]").remove();
            this.loadData();
        });
    },

    addAttachmentModal() {
        $("#modal")
            .modal("show")
            .find(".modal-content")
            .html(Attachment.ATTACHMENT_MODAL_TEMPLATE(this))
            .find(".submit-attachment")
            .on("click", () => {
                const files = this.getFilesToUpload();
                this.uploadAttachments(files);
            });
    },

    hideModal() {
        $("#modal").modal("hide").find(".modal-content").html("");
    },

    getFilesToUpload() {
        return $("#modal #attachments").prop("files");
    },

    uploadAttachments(files) {
        if (!files) {
            return;
        }

        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
            data.append("attachments", files[i], files[i].name);
        }
        showLoader("Uploading files", 10000);

        $.ajax({
            contentType: false, //Let the content type be properly inferred for the files
            processData: false, //Don't let jQuery process the files
            cache: false,

            url: this.url,
            type: "POST",

            dataType: "json",
            data: data,
        })
            .then((d) => {
                for (let i = 0; i < d.length; i++) {
                    this.insertAttachment(d[i]);
                }
            })
            .always(() => {
                hideLoader();
                this.hideModal();
                this.handleDragLeave();
            });
    },

    handleDroppedFiles(wrappedEvent) {
        const event = wrappedEvent.originalEvent;
        event.preventDefault();

        let files;
        if (event.dataTransfer.items) {
            files = Array.from(event.dataTransfer.items)
                .filter((item) => item.kind === "file")
                .map((item) => item.getAsFile());
        } else {
            files = Array.from(event.dataTransfer.files);
        }

        this.uploadAttachments(files);
    },

    handleDragOver(event) {
        event.preventDefault();
        $(this.content).addClass("dragging");
    },

    handleDragLeave() {
        $(this.content).removeClass("dragging");
    },
};

Attachment.TAG = document.registerElement("cm-attachments", {
    prototype: Object.create(HTMLElement.prototype, {
        attachedCallback: {
            value() {
                this.attachments = new Attachment({
                    elem: this,
                    endpoint: this.getAttribute("endpoint"),
                    data: $.extend({}, $(this).data()),
                    allow_upload: this.getAttribute("allow-upload") !== null,
                });
            },
        },
        detachedCallback: {
            value() {
                delete this.attachments;
            },
        },
    }),
});

export default Attachment;
