type Callback = (element: Element) => void;

const selectors: Record<string, Array<Callback>> = {};

if (window.MutationObserver) {
    new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            mutation.addedNodes.forEach(function (elem) {
                if (elem instanceof Element) {
                    for (const selector in selectors) {
                        elem.querySelectorAll(selector).forEach(function (e) {
                            selectors[selector].forEach(function (callback) {
                                callback(e);
                            });
                        });
                    }
                }
            });
        });
    }).observe(document, { subtree: true, childList: true });
}

$.applyTo = function (selector: never, callback: Callback) {
    if (!Object.prototype.hasOwnProperty.call(selectors, selector)) {
        selectors[selector] = [];
    }
    selectors[selector].push(callback);
    $(selector).each(function () {
        callback(this);
    });
};
