/* FIXME: BS5 */
(function (select2) {
    const formatPortfolio = (portfolio) => portfolio.name;

    select2.portfolio = {
        allowClear: true,
        closeOnSelect: false,
        minimumInputLength: 0,
        ajax: {
            url: () => router.route("api.internal.portfolios"),
            dataType: "json",
            quietMillis: 100,
            data: (term, page) => ({ term, page }),
            results: (data) => data,
        },
        formatResult: function (portfolio) {
            if (this.processResult) {
                this.processResult(portfolio);
            }
            return formatPortfolio(portfolio);
        },
        formatSelection: function (portfolio) {
            if (this.processSelection) {
                this.processSelection(portfolio);
            }
            return formatPortfolio(portfolio);
        },
        initSelection: select2.makeInitSelection((args) =>
            router.route("api.internal.portfolio", args),
        ),
    };

    select2.portfolio_multiple = $.extend({ multiple: true }, select2.portfolio);
})(window.comanage.select2);
