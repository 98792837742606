/* FIXME: BS5 */
import autosize from "autosize";
import Tooltip from "bootstrap/js/src/tooltip";

$.applyTo("time.timeago", function (e) {
    $(e).timeago();
});
$.applyTo("[data-toggle=tooltip]", function (e) {
    new Tooltip(e, { container: "body" });
});
$.applyTo("div[data-toggle=address]", function (e) {
    $(e).addressform();
});
$.applyTo("textarea", (e) => autosize($(e)).addClass("textarea--autosized"));
$.applyTo(".make-select2", function (e) {
    $(e).select2(e.dataset.select2Model ? comanage.select2[e.dataset.select2Model] : null);
});

$.applyTo("[data-toggle=intercom]", (el) => {
    el.addEventListener("click", (e) => {
        if (window.Intercom) {
            const { method, args = "[]" } = el.dataset;

            window.Intercom(method, ...JSON.parse(args));
            e.preventDefault();
        }
    });
});

$.applyTo("[data-action=copyToClipboard]", (el) => {
    el.addEventListener("click", () => {
        const textToCopy = el.dataset.contents ?? el.innerText;

        if (navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy).then(() => {
                alert("Copied to the clipboard");
            });
        } else {
            const textarea = document.createElement("textarea");
            textarea.value = textToCopy;
            textarea.style.top = "0";
            textarea.style.left = "0";
            textarea.style.position = "fixed";

            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();

            try {
                document.execCommand("copy");
            } catch (err) {
                window.prompt("Copy this text", textToCopy);
            } finally {
                document.body.removeChild(textarea);
            }
        }
    });
});
