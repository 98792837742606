/* FIXME: BS5 */
(function ($) {
    $.fn.fieldset = function (cmnd, ...args) {
        const options = $.extend($.fn.fieldset.defaults, typeof cmnd === "object" ? cmnd : {});

        this.each(function () {
            const $this = $(this);

            function rowHasData(row) {
                row = $(row).closest("[data-toggle=fieldset-row]");
                if (row.attr("required")) {
                    return true;
                }
                return row
                    .find(":input[data-required]")
                    .serializeArray()
                    .reduce(function (data, e) {
                        return data || !!e.value;
                    }, false);
            }

            if (!$this.data("fieldset")) {
                const fieldset = {
                    toggleButtons: function () {
                        const length = $this.find("[data-toggle=fieldset-row]").length;
                        if (length <= options.min_rows) {
                            $this.find("[data-toggle=fieldset-remove-row]").prop("disabled", true);
                        } else {
                            $this.find("[data-toggle=fieldset-remove-row]").prop("disabled", false);
                        }
                        if (length >= options.max_rows) {
                            $this.find("[data-toggle=fieldset-add-row]").hide();
                        } else {
                            $this.find("[data-toggle=fieldset-add-row]").show();
                        }
                    },
                    fixIds: function () {
                        $this.find("[data-toggle=fieldset-row]").each(function (index, element) {
                            $(element)
                                .find(":input")
                                .each(function () {
                                    const $input = $(this);
                                    if ($input.attr("name")) {
                                        const oldName = $input.attr("name"),
                                            newName = oldName.replace(/-[\d]-/, "-" + index + "-");

                                        $input.attr("name", newName).attr("id", newName);
                                    }
                                });
                        });
                    },

                    removeRow: function (row) {
                        $this.trigger("before.remove.row.fieldset");

                        if ($this.find("[data-toggle=fieldset-row]").length > options.min_rows) {
                            row.remove();
                            fieldset.fixIds();
                            fieldset.toggleButtons();
                            $this.trigger("after.remove.row.fieldset");
                        } else {
                            $this.trigger("failed.remove.row.fieldset");
                        }
                    },

                    countRows: function () {
                        return $this.find("[data-toggle=fieldset-row]").length;
                    },

                    //Append another row at the end
                    addRow: function (callback) {
                        $this.trigger("before.add.row.fieldset");

                        if (fieldset.countRows() < options.max_rows) {
                            const baseRow = $this.find("[data-toggle=fieldset-row]:first");

                            baseRow.find(".select2-container").each((i, s2) => {
                                const select2 = baseRow
                                    .find(s2.id.replace(/^s2id_/, "#"))
                                    .data("select2");
                                select2.opts.element
                                    .data("select2-opts", select2.opts)
                                    .select2("destroy")
                                    .addClass("fieldset-rebuild-select2");
                            });

                            const newRow = baseRow.clone(true, true);
                            const rebuildSelect2 = (i, r) => {
                                const $r = $(r);
                                $r.select2($r.data("select2-opts"))
                                    .removeData("select2-opts")
                                    .removeClass("fieldset-rebuild-select2");
                            };

                            newRow.removeClass("d-none");
                            newRow.find(":input:not([type=checkbox]):not([type=radio])").val("");
                            newRow.find(".has-error").removeClass("has-error");
                            newRow.find(".form-text").remove();
                            newRow.find("[data-default]").each(function (a, elem) {
                                elem.value = $(elem).data("default");
                            });

                            $this.find("[data-toggle=fieldset-row]:last").after(newRow);
                            fieldset.fixIds();
                            fieldset.toggleButtons();

                            baseRow.find(".fieldset-rebuild-select2").each(rebuildSelect2);
                            newRow.find(".fieldset-rebuild-select2").each(rebuildSelect2);

                            $this.trigger("after.add.row.fieldset", { row: newRow });

                            if (callback) {
                                callback(newRow);
                            }
                        } else {
                            $this.trigger("failed.add.row.fieldset");
                        }
                    },
                };

                $this.trigger("before.init.fieldset");

                $this.find("[data-toggle=fieldset-add-row]").click(() => fieldset.addRow());
                $this.find("[data-toggle=fieldset-remove-row]").click(function removeRowHandler() {
                    fieldset.removeRow($(this).closest("[data-toggle=fieldset-row]"));
                });

                $this.closest("form").submit(() => {
                    $this.find("[data-toggle=fieldset-row]").each(function () {
                        const $row = $(this);

                        if (!rowHasData($row)) {
                            $row.find(":input").each(function () {
                                $(this).prop("disabled", true);
                            });
                        }
                    });
                });

                fieldset.toggleButtons();
                $this.data("fieldset", fieldset);
                $this.trigger("after.init.fieldset");
            }

            if (typeof cmnd === "string") {
                if ($this.data("fieldset").hasOwnProperty(cmnd)) {
                    $this.data("fieldset")[cmnd](...args);
                } else {
                    console.error(`Unknown command ${cmnd}`);
                }
            }
        });

        return this;
    };

    $.fn.fieldset.defaults = {
        min_rows: 1,
        max_rows: 10,
    };
})(jQuery);

$("[data-toggle=fieldset]").fieldset();
