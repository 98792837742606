/* FIXME: BS5 */
(function (select2) {
    select2.makeContactSelect2 = (defaults) => ({
        allowClear: true,
        closeOnSelect: false,
        minimumInputLength: 0,
        ajax: {
            url: () => router.route("api.internal.people"),
            dataType: "json",
            quietMillis: 100,
            data: (term, page) => ({ ...defaults, term, page }),
            results: (data) => data,
        },
        formatResult: function (contact) {
            if (this.processResult) {
                this.processResult(contact);
            }

            return typeof defaults.type === "string"
                ? $f.contact_short(contact)
                : $f.contact_result(contact);
        },
        formatSelection: function (contact) {
            if (this.processSelection) {
                this.processSelection(contact);
            }

            return $f.contact_short(contact);
        },
        initSelection: select2.makeInitSelection((args) =>
            router.route("api.internal.people.person", args),
        ),
    });

    select2.contact = select2.makeContactSelect2({});
    select2.tenant = select2.makeContactSelect2({ type: "Tenant" });
    select2.manager = select2.makeContactSelect2({ type: "Manager" });
    select2.vendor = select2.makeContactSelect2({ type: "Vendor" });
    select2.owner = select2.makeContactSelect2({ type: "Owner" });

    select2.contact_multiple = $.extend({ multiple: true }, select2.contact);
    select2.tenant_multiple = $.extend({ multiple: true }, select2.tenant);
    select2.manager_multiple = $.extend({ multiple: true }, select2.manager);
    select2.vendor_multiple = $.extend({ multiple: true }, select2.vendor);
    select2.owner_multiple = $.extend({ multiple: true }, select2.owner);
})(window.comanage.select2);
