/* FIXME: BS5 */
(function (select2) {
    select2.workorder = {
        allowClear: true,
        closeOnSelect: false,
        minimumInputLength: 0,
        ajax: {
            url: () => router.route("api.internal.workorders"),
            dataType: "json",
            quietMillis: 100,
            data: (term, page) => ({ term, page }),
            results: (data) => data,
        },
        resultTemplate: tmpl`
      <strong>Work Order #<%=code%></strong> at <%=property_address%> (<%=other_party_name%>)<br>
      <small class="text-muted one-line">
        <%= DateTime.fromISO(updated_on).toLocaleString() %> - <%= description.truncate(200) %>
      </small>
    `,
        selectionTemplate: tmpl`
      <strong>Work Order #<%=code%></strong> at <%=property_address%> (<%=other_party_name%>)
    `,
        formatResult: function (workorder) {
            if (this.processResult) {
                this.processResult(workorder);
            }
            return this.resultTemplate(workorder);
        },
        formatSelection: function (workorder) {
            if (this.processSelection) {
                this.processSelection(workorder);
            }
            return this.selectionTemplate(workorder);
        },
        initSelection: select2.makeInitSelection((args) =>
            router.route("api.internal.workorder", args),
        ),
    };

    select2.workorder_multiple = $.extend({ multiple: true }, select2.workorder);
})(window.comanage.select2);
