/* FIXME: BS5 */
(() => {
    const { auth } = document.querySelector("html").dataset;

    if (auth) {
        $.ajaxSetup({
            headers: { Authorization: `Bearer ${auth}` },
            beforeSend: (jqXHR) => {
                jqXHR.crossdomain = true;
            },
        });
    }
})();
