/* FIXME: BS5 */
window.comanage = window.comanage || {};
window.comanage.select2 = window.comanage.select2 || {};
window.comanage.select2.makeInitSelection = (makeURL) =>
    function (element, callback) {
        const value = element[0].value;
        const { initialData } = element[0].dataset;

        if (initialData) {
            return callback(JSON.parse(initialData));
        }

        if (value && value.indexOf(",") !== -1) {
            $.when(
                ...value
                    .split(",")
                    .filter((id) => !!id)
                    .map((id) => $.getJSON(makeURL({ id }))),
            ).done((...responses) => callback(responses.map((r) => r[0])));
        } else if (value) {
            $.getJSON(makeURL({ id: value })).done(callback.bind(this));
        }
    };
