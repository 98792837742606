interface Window {
    _activeTransitionElements: Set<EventTarget>;
}

window._activeTransitionElements = new Set();

document.addEventListener("transitionrun", (e) => {
    e.target.addEventListener("transitionstart", ({ target }: TransitionEvent) => {
        window._activeTransitionElements.add(target);
    });

    e.target.addEventListener("transitionend", ({ target }: TransitionEvent) => {
        window._activeTransitionElements.delete(target);
    });

    e.target.addEventListener("transitioncancel", ({ target }: TransitionEvent) => {
        window._activeTransitionElements.delete(target);
    });
});
