/* FIXME: BS5 */

import {
    Route,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    RouteArguments,
    routes,
    URLArguments,
    URLRule,
} from "./routes";

declare global {
    interface Window {
        router: Router;
    }

    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace router {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        function route<R extends Route>(route: R, args?: RouteArguments[R]): string;
    }
}

const snake_to_pascal = (s: string) =>
    s
        .split("_")
        .map((part: string, i: number) =>
            i === 0 ? part : part[0].toUpperCase() + part.substring(1),
        )
        .join("");

const pascal_to_snake = (s: string) =>
    s.replace(/\.?([A-Z]+)/g, (_, y) => "_" + y.toLowerCase()).replace(/^_/, "");

class Rule {
    router: Router;
    endpoint: Route;
    rule: string;
    subdomain: string;

    args: { [name: string]: { name: string; converter: string; args: string } };

    constructor(router: Router, endpoint: Route, rule: string, subdomain: string) {
        this.router = router;
        this.endpoint = endpoint;
        this.rule = rule;
        this.subdomain = subdomain;
        this.args = {};

        this.rule = this.rule.replace(
            /(?:<(?:(\w+)(?:\(([^)]+)\))?:)?(\w+)>)/g,
            (_, converter, args, name) => {
                this.args[name] = { name, converter, args };
                return `|${name}|`;
            },
        );
    }

    build_url(options: Record<string, unknown>) {
        let url = document.location.protocol + "//";
        if (this.subdomain !== "") {
            url += this.subdomain + ".";
        }
        url += this.router.server_name;
        let path = this.rule;
        const args: URLArguments = {};

        for (const o in options) {
            if (
                Object.prototype.hasOwnProperty.call(options, o) ||
                Object.prototype.hasOwnProperty.call(options, pascal_to_snake(o))
            ) {
                const value = options[o] || options[pascal_to_snake(o)];

                if (Object.prototype.hasOwnProperty.call(this.args, o)) {
                    path = path.replace("|" + o + "|", value as string);
                } else if (Object.prototype.hasOwnProperty.call(this.args, pascal_to_snake(o))) {
                    path = path.replace("|" + pascal_to_snake(o) + "|", value as string);
                } else if (value !== undefined) {
                    args[o] = value;
                }
            }
        }

        const parameters = $.param(args, true);

        if (parameters === "") {
            return url + path;
        }
        return url + path + "?" + parameters;
    }
}

class Router {
    server_name: string;
    url_map: { [endpoint: string]: Rule[] };

    constructor(map: URLRule[]) {
        this.server_name = document.location.host;
        this.url_map = {};
        this.load_map(map);
    }

    load_map(rules: URLRule[]) {
        for (let i = 0; i < rules.length; i++) {
            this.add_rule(rules[i].endpoint, rules[i].rule, rules[i].subdomain);
        }
    }

    add_rule(endpoint: Route, rule: string, subdomain: string) {
        if (!Object.prototype.hasOwnProperty.call(this.url_map, endpoint)) {
            this.url_map[endpoint] = [];
        }

        this.url_map[endpoint].push(new Rule(this, endpoint, rule, subdomain));
    }

    route(route: Route, args: URLArguments = {}) {
        if (this.url_map[route] !== undefined) {
            // Find the rule that best matches the passed arguments
            const rules = this.url_map[route]
                .filter((rule) => {
                    return Object.keys(rule.args) // Remove any rules missing arguments
                        .reduce(
                            (matchRule, arg) =>
                                (Object.prototype.hasOwnProperty.call(args, arg) ||
                                    Object.prototype.hasOwnProperty.call(
                                        args,
                                        snake_to_pascal(arg),
                                    )) &&
                                matchRule,

                            true,
                        );
                })
                .map((rule) => ({
                    // Weight the rules based on how many matching args they have vs potential query string
                    rule,
                    weight: Object.keys(rule.args).reduce(
                        (sum, arg) =>
                            sum + (Object.prototype.hasOwnProperty.call(arg, sum) ? 1 : 0),
                        0,
                    ),
                }))
                .sort(
                    (
                        ruleA,
                        ruleB, //Find the highest weighted rule (Most matched arguments?
                    ) => (ruleA.weight < ruleB.weight ? 1 : ruleA.weight > ruleB.weight ? -1 : 0),
                );

            if (rules.length === 0) {
                throw new Error(
                    `No rule found matching ${route} with arguments ${
                        args ? Object.keys(args) : []
                    }`,
                );
            }

            return rules[0].rule.build_url(args);
        }
        throw new Error("Route " + route + " does not exist");
    }
}

window.router = new Router(routes);
