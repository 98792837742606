/* FIXME: BS5 */
$("img[data-error-fallback]").each(function () {
    const errorFallback = this.dataset.errorFallback;
    let failedOnce = false;

    $(this).on("error", function () {
        if (failedOnce) {
            this.src =
                document.location.origin +
                "/static/img/icons/" +
                this.src.split(".").pop() +
                ".png";
        } else {
            this.src = errorFallback;
            failedOnce = true;
        }
    });

    if (this.complete) {
        $(this).trigger("error");
    }
});
