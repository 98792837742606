/* FIXME: BS5 */
(function (select2) {
    const formatApplication = (application) =>
        `${application.tenants
            .map((at) => at.tenant.name)
            .englishJoin()
            .truncate(100)} to ${application.property.name}`;

    select2.application = {
        allowClear: true,
        closeOnSelect: false,
        minimumInputLength: 0,
        ajax: {
            url: () => router.route("api.internal.applications"),
            dataType: "json",
            quietMillis: 100,
            data: (term, page) => ({ term, page }),
            results: (data) => data,
        },
        formatResult: function (application) {
            if (this.processResult) {
                this.processResult(application);
            }
            return formatApplication(application);
        },
        formatSelection: function (application) {
            if (this.processSelection) {
                this.processSelection(application);
            }
            return formatApplication(application);
        },
        initSelection: select2.makeInitSelection((args) =>
            router.route("api.internal.application", args),
        ),
    };

    select2.application_multiple = $.extend({ multiple: true }, select2.application);
})(window.comanage.select2);
