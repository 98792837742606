/* FIXME: BS5 */
interface JQuery {
    serializeObject<RT = Record<string, unknown>>(): RT;
}

(function (jQuery) {
    jQuery.fn.serializeObject = function <RT = Record<string, unknown>>(): RT {
        const push_counters: Record<string | number, number> = {};
        const patterns = {
            validate: /^[a-zA-Z][a-zA-Z0-9\-_]*(?:\[(?:\d*|[a-zA-Z0-9_]+)])*$/,
            key: /[a-zA-Z0-9_]+|(?=\[])/g,
            push: /^$/,
            fixed: /^\d+$/,
            named: /^[a-zA-Z0-9_]+$/,
        };
        let json: Partial<RT> = {};

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function build(base: any, key: any, value: any) {
            base[key] = value;
            return base;
        }

        function push_counter(key: string): number {
            if (push_counters[key] === undefined) {
                push_counters[key] = 0;
            }
            return push_counters[key]++;
        }

        jQuery.each(jQuery(this).serializeArray(), function () {
            // skip invalid keys
            if (!patterns.validate.test(this.name)) {
                return;
            }

            const keys = this.name.match(patterns.key);
            let merge: unknown = this.value;
            let reverse_key: string = this.name;
            let k;

            while ((k = keys.pop()) !== undefined) {
                // adjust reverse_key
                reverse_key = reverse_key.replace(new RegExp("\\[" + k + "\\]$"), "");

                // push
                if (k.match(patterns.push)) {
                    merge = build([], push_counter(reverse_key), merge);
                }

                // fixed
                else if (k.match(patterns.fixed)) {
                    merge = build([], k, merge);
                }

                // named
                else if (k.match(patterns.named)) {
                    merge = build({}, k, merge);
                }
            }

            json = jQuery.extend(true, json, merge);
        });

        return json as RT;
    };
})(jQuery);
