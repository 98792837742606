/* FIXME: BS5 */
import "jquery";

import _active from "./_active";

const Note = function (options) {
    this.options = $.extend({}, Note.DEFAULTS, options);

    this.elem = this.options.elem;
    this.url = router.route(this.options.endpoint, this.options.data);

    this.page = 1;

    this.init();
};

$.extend(Note, {
    DEFAULTS: {
        elem: null,
        endpoint: null,
        data: null,
    },

    TABLE_TEMPLATE: tmpl`
    <fieldset>
      <legend class="table-legend">
        Notes and Events
        <span class="legend-controls">
          <button type="button" name="add_note" class="d-none btn btn-light add_note">
            <i class="fa fa-comment"></i> Add Note
          </button>
        </span>
      </legend>
      <table class="table table-sm notes-content" style="margin-bottom: 0;">
      </table>
      <div class="row">
        <div class="col-full notes-paginator"></div>
      </div>
    </fieldset>
  `,

    NOTE_TEMPLATE: tmpl`
      <tbody class="note-row" data-note="<%= note_id %>">
        <tr class="note-header note-<%= type.toLowerCase() %>">
          <th>
            <span class="pull-right">
              <small class="text-muted">
                <% if (can_edit) { %>
                  <a class="toggle_private" href="#" data-note="<%= note_id %>">
                <% } %>
                <i class="fa fa-<%= __obj.private ? "lock" : "unlock" %>"></i>
                <%= __obj.private ? "Private" : "Public" %>
                <% if (can_edit) { %>
                  </a>
                <% } %>
              </small>
              <%= type %>
            </span>
            <% if (has_author) { %>
              <%= first_name %> <%= last_name %> (<%= organization_name %>)
            <% } else { %>
              coManage
            <% } %>
            <small class="text-muted">
              <% var date = DateTime.fromISO(time); %>
              <time class="timeago" datetime="<%= date.toISO() %>"><%= date.toLocaleString() %></time>
            </small>
          </th>
        </tr>
        <tr class="note-body">
          <td><%= note %></td>
        </tr>
      </tbody>
  `,

    PAGINATOR_TEMPLATE: tmpl`
    <ul class="pager" style="margin-top: 2px;">
      <% if (page > 1) { %>
        <li class="previous"><a href="#">&larr; Back</a></li>
      <% } %>
      <% if (more) { %>
        <li class="next"><a href="#">&rarr; Next</a></li>
      <% } %>
    </ul>
  `,

    NOTE_MODAL_TEMPLATE: tmpl`
    <div class="modal-header">
      <h4 class="modal-title">Add a Note</h4>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body form-horizontal g-3">
      <div class="form-group">
        <div class="col-lg-12 col-md-12">
          <textarea class="form-control" rows="6" cols="30" name="note"></textarea>
        </div>
      </div>
      <div class="form-group">
        <label for="note-private" class="col-form-left col-label">
          Private note?
        </label>
        <div class="col-form-right">
          <input type="checkbox" id="note-private" name="private" value="T" checked>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-light" data-bs-dismiss="modal" type="button">Cancel</button>
      <button name="submit" class="btn btn-success submit-note">Add Note</button>
    </div>
  `,

    NO_NOTES: `<tbody class="no-notes"><tr><td>No notes or events found</td></tr></tbody>`,
});

Note["newInstance"] = function (elem) {
    const options = {
        elem: elem,
        endpoint: $(elem).data("endpoint"),
        data: {
            id: $(elem).data("id"),
        },
    };

    return new Note(options);
};

Note.prototype = {
    init: function () {
        _active.note = this;
        this.initWidget();

        this.loadData();
    },

    initWidget: function () {
        this.elem.innerHTML = Note.TABLE_TEMPLATE(this);

        this.content = this.elem.querySelector(".notes-content");
        this.paginator = this.elem.querySelector(".notes-paginator");

        this.elem.querySelector(".add_note").onclick = this.addNoteModal.bind(this);
    },

    loadData: function () {
        this.loading = true;

        $.getJSON(this.url, { page: this.page })
            .then((d) => {
                this.handleDataLoaded(d);
            })
            .always(() => {
                this.loading = false;
            });
    },

    handleDataLoaded: function (data) {
        this.notes = data.results;
        this.page = data.page;
        this.more = data.more;
        this.total = data.total;
        this.can_post = data.can_post;

        this.updateAddNote();

        this.clearContent();
        this.renderContent();
        this.renderPaginator();
        this.addNoteHandlers();
    },

    nextPage: function (e) {
        if (this.more && !this.loading) {
            this.page += 1;
            this.loadData();
        }
        e.preventDefault();
    },
    previousPage: function (e) {
        if (this.page > 1 && !this.loading) {
            this.page--;
            this.loadData();
        }
        e.preventDefault();
    },

    updateAddNote: function () {
        const add_note_btn = $(this.elem.querySelector(".add_note"));
        add_note_btn.toggleClass("d-none", !this.can_post);
    },

    clearContent: function () {
        this.content.innerHTML = "";
    },

    renderContent: function () {
        if (!this.notes.length) {
            this.content.innerHTML = Note.NO_NOTES;
        } else {
            for (let i = 0; i < this.notes.length; i++) {
                this.content.innerHTML += Note.NOTE_TEMPLATE(this.notes[i]);
            }
            $(this.content.querySelectorAll(".timeago")).timeago();
        }
    },

    renderPaginator: function () {
        this.paginator.innerHTML = Note.PAGINATOR_TEMPLATE(this);

        const next = this.paginator.querySelector(".next"),
            previous = this.paginator.querySelector(".previous");
        if (next) {
            next.onclick = this.nextPage.bind(this);
        }
        if (previous) {
            previous.onclick = this.previousPage.bind(this);
        }
    },

    addNoteHandlers: function () {
        const notes = this.content.querySelectorAll(".note-row");
        for (let i = 0; i < notes.length; i++) {
            const note = notes[i];
            $(note.querySelector(".toggle_private")).click(this.togglePrivate.bind(this));
        }
    },

    addNoteModal: function () {
        $("#modal")
            .modal("show")
            .find(".modal-content")
            .html(Note.NOTE_MODAL_TEMPLATE(this))
            .find(".submit-note")
            .click(this.submitNoteFromModal.bind(this));
    },

    insertNote: function (note) {
        $(this.content)
            .prepend(Note.NOTE_TEMPLATE(note))
            .find(".toggle_private")
            .click(this.togglePrivate.bind(this));

        $(this.content.querySelectorAll(".timeago")).timeago();

        this.removeNoNotesMessage();
    },

    removeNoNotesMessage: function () {
        const no_notes_message = this.content.querySelector(".no-notes");
        if (no_notes_message) {
            no_notes_message.remove();
        }
    },

    replaceNote: function (note) {
        $(this.content)
            .find(".note-row[data-note=" + note.note_id + "]")
            .replaceWith(Note.NOTE_TEMPLATE(note));

        $(this.content)
            .find(".note-row[data-note=" + note.note_id + "]")
            .find(".toggle_private")
            .click(this.togglePrivate.bind(this));

        $(this.content.querySelectorAll(".timeago")).timeago();
    },

    togglePrivate: function (e) {
        $.ajax({
            type: "PUT",
            url: this.url,
            data: { note_id: $(e.target).data("note") },
        }).then((d) => {
            this.replaceNote(d);
        });
    },

    submitNoteFromModal: function () {
        showLoader("Posting note");

        $.ajax({
            type: "POST",
            url: this.url,
            data: $("#modal").find(":input").serialize(),
        })
            .then((d) => {
                this.insertNote(d);
            })
            .always(() => {
                $("#modal").modal("hide").find(".modal-content").html("");
                hideLoader();
            });
    },
};

Note.TAG = document.registerElement("cm-notes", {
    prototype: Object.create(HTMLElement.prototype, {
        attachedCallback: {
            value: function () {
                this.note = new Note({
                    elem: this,
                    endpoint: this.getAttribute("endpoint"),
                    data: $.extend({}, $(this).data()),
                });
            },
        },
        detachedCallback: {
            value: function () {
                delete this.note;
            },
        },
    }),
});

export default Note;
