type Arg = unknown;
export type URLArguments<T = Record<string, unknown>> = T & { [key: string]: Arg };
export type Route = keyof RouteArguments;
export interface RouteArguments {
    "api.auth": URLArguments;
    "api.internal.accounting.balance": URLArguments<{ gl_account_id: Arg }>;
    "api.internal.accounting.balances": URLArguments;
    "api.internal.accounting.reconciliation": URLArguments<{ reconciliation_id: Arg }>;
    "api.internal.accounting.reconciliations": URLArguments;
    "api.internal.accounting.reconciliations.prior": URLArguments;
    "api.internal.accounting.reconciliations.reconcilables": URLArguments;
    "api.internal.admin.ach_file": URLArguments<{ ach_file_id: Arg }>;
    "api.internal.admin.ach_files": URLArguments;
    "api.internal.admin.ach_response_files": URLArguments;
    "api.internal.admin.configuration": URLArguments<{ key: Arg }>;
    "api.internal.admin.configurations": URLArguments;
    "api.internal.admin.contact": URLArguments<{ id: Arg }>;
    "api.internal.admin.contacts": URLArguments;
    "api.internal.admin.cron_job": URLArguments<{ job_name: Arg }>;
    "api.internal.admin.cron_job.enqueue": URLArguments<{ job_name: Arg }>;
    "api.internal.admin.cron_jobs": URLArguments;
    "api.internal.admin.financial_account": URLArguments<{ id: Arg }>;
    "api.internal.admin.financial_accounts": URLArguments;
    "api.internal.admin.history.email": URLArguments<{ email_history_id: Arg }>;
    "api.internal.admin.history.email.attachment": URLArguments<{ email_history_attachment_id: Arg; email_history_id: Arg }>;
    "api.internal.admin.history.email.attachment.download": URLArguments<{ email_history_attachment_id: Arg; email_history_id: Arg }>;
    "api.internal.admin.history.email.attachments": URLArguments<{ email_history_id: Arg }>;
    "api.internal.admin.history.email.events": URLArguments<{ email_history_id: Arg }>;
    "api.internal.admin.history.emails": URLArguments;
    "api.internal.admin.history.emails.attachments.mimetypes": URLArguments;
    "api.internal.admin.invite_token": URLArguments<{ token: Arg }>;
    "api.internal.admin.invite_tokens": URLArguments;
    "api.internal.admin.organization": URLArguments<{ id: Arg }>;
    "api.internal.admin.organizations": URLArguments;
    "api.internal.admin.payments": URLArguments;
    "api.internal.admin.plaid.account": URLArguments<{ account_id: Arg }>;
    "api.internal.admin.plaid.accounts": URLArguments;
    "api.internal.admin.plaid.item": URLArguments<{ item_id: Arg }>;
    "api.internal.admin.plaid.item.refresh": URLArguments<{ item_id: Arg }>;
    "api.internal.admin.plaid.items": URLArguments;
    "api.internal.admin.plaid.transaction": URLArguments<{ transaction_id: Arg }>;
    "api.internal.admin.plaid.transactions": URLArguments;
    "api.internal.admin.reversal": URLArguments;
    "api.internal.admin.screening.reports": URLArguments;
    "api.internal.admin.screening.request": URLArguments<{ screening_request_id: Arg }>;
    "api.internal.admin.screening.request.cancel": URLArguments<{ screening_request_id: Arg }>;
    "api.internal.admin.screening.request.pull_report": URLArguments<{ report_type: Arg; screening_request_id: Arg }>;
    "api.internal.admin.screening.request.pull_reports": URLArguments<{ screening_request_id: Arg }>;
    "api.internal.admin.screening.request.report": URLArguments<{ report_type: Arg; screening_request_id: Arg }>;
    "api.internal.admin.screening.requests": URLArguments;
    "api.internal.admin.task.document_batch": URLArguments<{ Model: Arg; Schema: Arg; task_id: Arg }>;
    "api.internal.admin.task.document_batch.retry": URLArguments<{ Model: Arg; Schema: Arg; task_id: Arg }>;
    "api.internal.admin.task.document_batches": URLArguments<{ Model: Arg; Schema: Arg }>;
    "api.internal.admin.task.report_batch": URLArguments<{ Model: Arg; Schema: Arg; task_id: Arg }>;
    "api.internal.admin.task.report_batch.retry": URLArguments<{ Model: Arg; Schema: Arg; task_id: Arg }>;
    "api.internal.admin.task.report_batches": URLArguments<{ Model: Arg; Schema: Arg }>;
    "api.internal.admin.task_status": URLArguments<{ task_id: Arg }>;
    "api.internal.admin.transaction": URLArguments<{ transaction_id: Arg }>;
    "api.internal.admin.transactions": URLArguments;
    "api.internal.admin.user": URLArguments<{ user_id: Arg }>;
    "api.internal.admin.users": URLArguments;
    "api.internal.application": URLArguments<{ id: Arg }>;
    "api.internal.application.attachments": URLArguments<{ id: Arg }>;
    "api.internal.application.tenants": URLArguments<{ id: Arg }>;
    "api.internal.applications": URLArguments;
    "api.internal.automation": URLArguments<{ automation_id: Arg }>;
    "api.internal.automation.workflow": URLArguments<{ automation_id: Arg; workflow_id: Arg }>;
    "api.internal.automation.workflows": URLArguments<{ automation_id: Arg }>;
    "api.internal.automations": URLArguments;
    "api.internal.automations.entities": URLArguments;
    "api.internal.dashboard.invoice_age": URLArguments;
    "api.internal.dashboard.notifications": URLArguments;
    "api.internal.dashboard.pending_applications": URLArguments;
    "api.internal.dashboard.work_order_age": URLArguments;
    "api.internal.dashboard.work_order_status": URLArguments;
    "api.internal.document.batch": URLArguments<{ batch_id: Arg }>;
    "api.internal.document.batch.task": URLArguments<{ batch_id: Arg; task_id: Arg }>;
    "api.internal.document.batches": URLArguments;
    "api.internal.documents.template": URLArguments<{ id: Arg }>;
    "api.internal.documents.template.batches": URLArguments<{ id: Arg }>;
    "api.internal.documents.template.preview": URLArguments<{ id: Arg }>;
    "api.internal.documents.template.render": URLArguments<{ id: Arg }>;
    "api.internal.documents.templates": URLArguments;
    "api.internal.events": URLArguments | URLArguments<{ entity_type: Arg }>;
    "api.internal.events.entity": URLArguments<{ entity_id: Arg; entity_type: Arg }>;
    "api.internal.events.entity.attachment": URLArguments<{ entity_id: Arg; entity_type: Arg }>;
    "api.internal.events.entity.comment": URLArguments<{ entity_id: Arg; entity_type: Arg }>;
    "api.internal.events.entity.events": URLArguments<{ entity_id: Arg; entity_type: Arg }>;
    "api.internal.events.entity.status": URLArguments<{ entity_id: Arg; entity_type: Arg }>;
    "api.internal.events.entity.subscriptions": URLArguments<{ entity_id: Arg; entity_type: Arg; subscription_id: Arg }>;
    "api.internal.file": URLArguments<{ attachment_id: Arg }>;
    "api.internal.files": URLArguments;
    "api.internal.financial_account": URLArguments<{ id: Arg }>;
    "api.internal.financial_account.transactions": URLArguments<{ financial_account_id: Arg }>;
    "api.internal.financial_accounts": URLArguments;
    "api.internal.financial_accounts.transactions.categories": URLArguments | URLArguments<{ financial_account_id: Arg }>;
    "api.internal.gl_account": URLArguments<{ account_id: Arg }>;
    "api.internal.gl_accounts": URLArguments;
    "api.internal.invoice": URLArguments;
    "api.internal.invoice.automation": URLArguments<{ automation_id: Arg; entity_id: Arg }>;
    "api.internal.invoice.automations": URLArguments<{ entity_id: Arg }>;
    "api.internal.invoice.batch": URLArguments<{ id: Arg }>;
    "api.internal.invoice.batches": URLArguments;
    "api.internal.invoice.invoice": URLArguments<{ id: Arg }>;
    "api.internal.invoice.invoice.attachments": URLArguments<{ id: Arg }>;
    "api.internal.invoice.line_items": URLArguments;
    "api.internal.invoice.payable": URLArguments;
    "api.internal.invoice.payments": URLArguments;
    "api.internal.invoice.property_invoices": URLArguments;
    "api.internal.invoice.reverse": URLArguments<{ id: Arg }>;
    "api.internal.invoice.reverse_many": URLArguments;
    "api.internal.invoice.term": URLArguments<{ id: Arg }>;
    "api.internal.invoice.terms": URLArguments;
    "api.internal.invoice.transactions": URLArguments;
    "api.internal.journal_entries": URLArguments;
    "api.internal.journal_entry": URLArguments<{ journal_entry_id: Arg }>;
    "api.internal.journal_entry.reversal": URLArguments<{ journal_entry_id: Arg }>;
    "api.internal.marketing.offer": URLArguments<{ id: Arg }>;
    "api.internal.marketing.offers": URLArguments;
    "api.internal.marketing.search": URLArguments<{ saved_search_id: Arg }>;
    "api.internal.marketing.searches": URLArguments;
    "api.internal.marketing.unit": URLArguments<{ unit_id: Arg }>;
    "api.internal.marketing.units": URLArguments;
    "api.internal.marketplace": URLArguments;
    "api.internal.marketplace.listing": URLArguments<{ id: Arg }>;
    "api.internal.occupancies": URLArguments;
    "api.internal.occupancy": URLArguments<{ id: Arg }>;
    "api.internal.occupancy.attachments": URLArguments<{ id: Arg }>;
    "api.internal.occupancy.automation": URLArguments<{ automation_id: Arg; entity_id: Arg }>;
    "api.internal.occupancy.automations": URLArguments<{ entity_id: Arg }>;
    "api.internal.occupancy.payment": URLArguments<{ occupancy_id: Arg; occupancy_payment_id: Arg }>;
    "api.internal.occupancy.payments": URLArguments<{ occupancy_id: Arg }>;
    "api.internal.occupancy.tenants": URLArguments<{ id: Arg }>;
    "api.internal.occupancy.unpaid_invoices": URLArguments<{ occupancy_id: Arg }>;
    "api.internal.organization": URLArguments;
    "api.internal.organization.by_id": URLArguments<{ id: Arg }>;
    "api.internal.organization.groups": URLArguments;
    "api.internal.people": URLArguments;
    "api.internal.people.groups": URLArguments;
    "api.internal.people.person": URLArguments<{ id: Arg }>;
    "api.internal.people.person.attachments": URLArguments<{ id: Arg }>;
    "api.internal.people.person.groups": URLArguments<{ contact_id: Arg }>;
    "api.internal.plaid.link": URLArguments;
    "api.internal.plaid.link.token": URLArguments;
    "api.internal.portal.files": URLArguments;
    "api.internal.portal.invoices": URLArguments;
    "api.internal.portal.login": URLArguments;
    "api.internal.portal.payments": URLArguments;
    "api.internal.portal.receipts": URLArguments;
    "api.internal.portal.recurring_payment": URLArguments<{ automation_id: Arg; occupancy_id: Arg }>;
    "api.internal.portal.recurring_payments": URLArguments;
    "api.internal.portal.register": URLArguments;
    "api.internal.portal.screening.connect": URLArguments;
    "api.internal.portal.screening.exam.submit": URLArguments<{ exam_id: Arg }>;
    "api.internal.portal.screening.exams": URLArguments;
    "api.internal.portfolio": URLArguments<{ portfolio_id: Arg }>;
    "api.internal.portfolio.all_properties": URLArguments;
    "api.internal.portfolio.financial_account": URLArguments<{ financial_account_id: Arg; portfolio_id: Arg }>;
    "api.internal.portfolio.financial_accounts": URLArguments<{ portfolio_id: Arg }>;
    "api.internal.portfolio.owner": URLArguments<{ owner_id: Arg; portfolio_id: Arg }>;
    "api.internal.portfolio.owners": URLArguments<{ portfolio_id: Arg }>;
    "api.internal.portfolio.properties": URLArguments<{ portfolio_id: Arg }>;
    "api.internal.portfolio.property": URLArguments<{ portfolio_id: Arg; property_id: Arg }>;
    "api.internal.portfolios": URLArguments;
    "api.internal.properties": URLArguments;
    "api.internal.property": URLArguments<{ id: Arg }>;
    "api.internal.property.automation": URLArguments<{ automation_id: Arg; entity_id: Arg }>;
    "api.internal.property.automations": URLArguments<{ entity_id: Arg }>;
    "api.internal.property.offer": URLArguments<{ id: Arg }>;
    "api.internal.property.offer.attachments": URLArguments<{ id: Arg }>;
    "api.internal.property.offers": URLArguments;
    "api.internal.property.quick.multi": URLArguments;
    "api.internal.property.quick.single": URLArguments;
    "api.internal.property.unit": URLArguments<{ id: Arg }> | URLArguments<{ id: Arg; property_id: Arg }>;
    "api.internal.property.unit.attachments": URLArguments<{ id: Arg; property_id: Arg }>;
    "api.internal.property.units": URLArguments | URLArguments<{ id: Arg }>;
    "api.internal.receivable.bank_deposit": URLArguments<{ bank_deposit_id: Arg }>;
    "api.internal.receivable.bank_deposit.deposit_items": URLArguments<{ bank_deposit_id: Arg }>;
    "api.internal.receivable.bank_deposits": URLArguments;
    "api.internal.receivable.invoice": URLArguments<{ receivable_id: Arg }>;
    "api.internal.receivable.invoice.batch": URLArguments<{ id: Arg }>;
    "api.internal.receivable.invoice.batch.reversal": URLArguments<{ id: Arg }>;
    "api.internal.receivable.invoice.batches": URLArguments;
    "api.internal.receivable.invoice.reversal": URLArguments<{ receivable_id: Arg }>;
    "api.internal.receivable.invoices": URLArguments;
    "api.internal.receivable.receipt": URLArguments<{ receipt_id: Arg }>;
    "api.internal.receivable.receipt.reapply": URLArguments<{ receipt_id: Arg }>;
    "api.internal.receivable.receipt.reversal": URLArguments<{ receipt_id: Arg }>;
    "api.internal.receivable.receipts": URLArguments;
    "api.internal.receivable_charge.automation": URLArguments<{ automation_id: Arg; entity_id: Arg }>;
    "api.internal.receivable_charge.automations": URLArguments<{ entity_id: Arg }>;
    "api.internal.receivable_credit.automation": URLArguments<{ automation_id: Arg; entity_id: Arg }>;
    "api.internal.receivable_credit.automations": URLArguments<{ entity_id: Arg }>;
    "api.internal.receivable_invoice.automation": URLArguments<{ automation_id: Arg; entity_id: Arg }>;
    "api.internal.receivable_invoice.automations": URLArguments<{ entity_id: Arg }>;
    "api.internal.receivable_prepayment.automation": URLArguments<{ automation_id: Arg; entity_id: Arg }>;
    "api.internal.receivable_prepayment.automations": URLArguments<{ entity_id: Arg }>;
    "api.internal.receivable_receipt.automation": URLArguments<{ automation_id: Arg; entity_id: Arg }>;
    "api.internal.receivable_receipt.automations": URLArguments<{ entity_id: Arg }>;
    "api.internal.receivable_receipt_payment.automation": URLArguments<{ automation_id: Arg; entity_id: Arg }>;
    "api.internal.receivable_receipt_payment.automations": URLArguments<{ entity_id: Arg }>;
    "api.internal.report.batch": URLArguments<{ batch_id: Arg }>;
    "api.internal.report.batch.create": URLArguments;
    "api.internal.report.report3": URLArguments<{ name: Arg }>;
    "api.internal.report.report3.export": URLArguments<{ format: Arg; name: Arg }>;
    "api.internal.report.report3.pending": URLArguments<{ name: Arg; task_id: Arg }>;
    "api.internal.report.report3.report": URLArguments<{ name: Arg; report_id: Arg }>;
    "api.internal.report.report3.save": URLArguments<{ name: Arg }>;
    "api.internal.screening.request": URLArguments<{ id: Arg }>;
    "api.internal.screening.request.cancel": URLArguments<{ id: Arg }>;
    "api.internal.screening.request.pull_reports": URLArguments<{ id: Arg }>;
    "api.internal.screening.requests": URLArguments;
    "api.internal.search": URLArguments;
    "api.internal.settings.cash_management_rule": URLArguments<{ cash_management_rule_id: Arg }>;
    "api.internal.settings.cash_management_rules": URLArguments;
    "api.internal.transunion.authentication": URLArguments;
    "api.internal.transunion.reports": URLArguments;
    "api.internal.transunion.system_status": URLArguments;
    "api.internal.unit.automation": URLArguments<{ automation_id: Arg; entity_id: Arg }>;
    "api.internal.unit.automations": URLArguments<{ entity_id: Arg }>;
    "api.internal.unit.utilities": URLArguments<{ unit_id: Arg }>;
    "api.internal.unit.utility": URLArguments<{ unit_id: Arg; utility_id: Arg }>;
    "api.internal.user": URLArguments;
    "api.internal.user.email_exists": URLArguments;
    "api.internal.user.forgot_password": URLArguments;
    "api.internal.user.login_token": URLArguments;
    "api.internal.user.portal_login_token": URLArguments;
    "api.internal.user.reset_password": URLArguments;
    "api.internal.work_order.automation": URLArguments<{ automation_id: Arg; entity_id: Arg }>;
    "api.internal.work_order.automations": URLArguments<{ entity_id: Arg }>;
    "api.internal.workorder": URLArguments<{ id: Arg }>;
    "api.internal.workorder.accept": URLArguments<{ id: Arg }>;
    "api.internal.workorder.attachments": URLArguments<{ id: Arg }>;
    "api.internal.workorder.batch": URLArguments<{ id: Arg }>;
    "api.internal.workorder.batches": URLArguments;
    "api.internal.workorder.submit": URLArguments<{ id: Arg }>;
    "api.internal.workorders": URLArguments;
    "api.marketing.offer": URLArguments<{ offer_id: Arg }>;
    "api.marketing.offers": URLArguments;
    "api.marketing.unit": URLArguments<{ unit_id: Arg }>;
    "api.marketing.units": URLArguments;
    "api.membership": URLArguments;
    "api.membership_token": URLArguments<{ membership_id: Arg }>;
    "api.status": URLArguments;
    "api.token_refresh": URLArguments;
    "api.user": URLArguments;
    "api.version": URLArguments;
    "invoice.print_batch": URLArguments;
    "workorder.batch.create_properties": URLArguments;
    "workorder.print_batch": URLArguments;
}

export type URLRule = { rule: string; subdomain: string; endpoint: Route };
export const routes: URLRule[] = [
    {
        "rule": "/auth",
        "subdomain": "api",
        "endpoint": "api.auth"
    },
    {
        "rule": "/internal/accounting/balances/<uuid:gl_account_id>",
        "subdomain": "api",
        "endpoint": "api.internal.accounting.balance"
    },
    {
        "rule": "/internal/accounting/balances",
        "subdomain": "api",
        "endpoint": "api.internal.accounting.balances"
    },
    {
        "rule": "/internal/accounting/reconciliation/<uuid:reconciliation_id>",
        "subdomain": "api",
        "endpoint": "api.internal.accounting.reconciliation"
    },
    {
        "rule": "/internal/accounting/reconciliations",
        "subdomain": "api",
        "endpoint": "api.internal.accounting.reconciliations"
    },
    {
        "rule": "/internal/accounting/reconciliations/prior",
        "subdomain": "api",
        "endpoint": "api.internal.accounting.reconciliations.prior"
    },
    {
        "rule": "/internal/accounting/reconciliations/reconcilables",
        "subdomain": "api",
        "endpoint": "api.internal.accounting.reconciliations.reconcilables"
    },
    {
        "rule": "/internal/property/applications/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.application"
    },
    {
        "rule": "/internal/property/applications/<uuid:id>/attachments",
        "subdomain": "api",
        "endpoint": "api.internal.application.attachments"
    },
    {
        "rule": "/internal/property/applications/<uuid:id>/tenants",
        "subdomain": "api",
        "endpoint": "api.internal.application.tenants"
    },
    {
        "rule": "/internal/property/applications",
        "subdomain": "api",
        "endpoint": "api.internal.applications"
    },
    {
        "rule": "/internal/automations/<uuid:automation_id>",
        "subdomain": "api",
        "endpoint": "api.internal.automation"
    },
    {
        "rule": "/internal/automations/<uuid:automation_id>/workflows/<uuid:workflow_id>",
        "subdomain": "api",
        "endpoint": "api.internal.automation.workflow"
    },
    {
        "rule": "/internal/automations/<uuid:automation_id>/workflows",
        "subdomain": "api",
        "endpoint": "api.internal.automation.workflows"
    },
    {
        "rule": "/internal/automations/",
        "subdomain": "api",
        "endpoint": "api.internal.automations"
    },
    {
        "rule": "/internal/automations/entities",
        "subdomain": "api",
        "endpoint": "api.internal.automations.entities"
    },
    {
        "rule": "/internal/dashboard/invoice_age",
        "subdomain": "api",
        "endpoint": "api.internal.dashboard.invoice_age"
    },
    {
        "rule": "/internal/dashboard/notifications",
        "subdomain": "api",
        "endpoint": "api.internal.dashboard.notifications"
    },
    {
        "rule": "/internal/dashboard/pending_applications",
        "subdomain": "api",
        "endpoint": "api.internal.dashboard.pending_applications"
    },
    {
        "rule": "/internal/dashboard/work_order_age",
        "subdomain": "api",
        "endpoint": "api.internal.dashboard.work_order_age"
    },
    {
        "rule": "/internal/dashboard/work_order_status",
        "subdomain": "api",
        "endpoint": "api.internal.dashboard.work_order_status"
    },
    {
        "rule": "/internal/documents/batch/<uuid:batch_id>",
        "subdomain": "api",
        "endpoint": "api.internal.document.batch"
    },
    {
        "rule": "/internal/documents/batch/<uuid:batch_id>/task/<uuid:task_id>",
        "subdomain": "api",
        "endpoint": "api.internal.document.batch.task"
    },
    {
        "rule": "/internal/documents/batch",
        "subdomain": "api",
        "endpoint": "api.internal.document.batches"
    },
    {
        "rule": "/internal/documents/template/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.documents.template"
    },
    {
        "rule": "/internal/documents/template/<uuid:id>/batch",
        "subdomain": "api",
        "endpoint": "api.internal.documents.template.batches"
    },
    {
        "rule": "/internal/documents/template/<uuid:id>/preview",
        "subdomain": "api",
        "endpoint": "api.internal.documents.template.preview"
    },
    {
        "rule": "/internal/documents/template/<uuid:id>/render",
        "subdomain": "api",
        "endpoint": "api.internal.documents.template.render"
    },
    {
        "rule": "/internal/documents/templates",
        "subdomain": "api",
        "endpoint": "api.internal.documents.templates"
    },
    {
        "rule": "/internal/events",
        "subdomain": "api",
        "endpoint": "api.internal.events"
    },
    {
        "rule": "/internal/events/<entity_type>",
        "subdomain": "api",
        "endpoint": "api.internal.events"
    },
    {
        "rule": "/internal/events/<entity_type>/<entity_id>",
        "subdomain": "api",
        "endpoint": "api.internal.events.entity"
    },
    {
        "rule": "/internal/events/<entity_type>/<entity_id>/attachment",
        "subdomain": "api",
        "endpoint": "api.internal.events.entity.attachment"
    },
    {
        "rule": "/internal/events/<entity_type>/<entity_id>/comment",
        "subdomain": "api",
        "endpoint": "api.internal.events.entity.comment"
    },
    {
        "rule": "/internal/events/<entity_type>/<entity_id>/events",
        "subdomain": "api",
        "endpoint": "api.internal.events.entity.events"
    },
    {
        "rule": "/internal/events/<entity_type>/<entity_id>/status",
        "subdomain": "api",
        "endpoint": "api.internal.events.entity.status"
    },
    {
        "rule": "/internal/events/<entity_type>/<entity_id>/<subscription_id>",
        "subdomain": "api",
        "endpoint": "api.internal.events.entity.subscriptions"
    },
    {
        "rule": "/internal/file/<uuid:attachment_id>",
        "subdomain": "api",
        "endpoint": "api.internal.file"
    },
    {
        "rule": "/internal/files",
        "subdomain": "api",
        "endpoint": "api.internal.files"
    },
    {
        "rule": "/internal/financial_accounts/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.financial_account"
    },
    {
        "rule": "/internal/financial_account/<uuid:financial_account_id>/transactions",
        "subdomain": "api",
        "endpoint": "api.internal.financial_account.transactions"
    },
    {
        "rule": "/internal/financial_accounts",
        "subdomain": "api",
        "endpoint": "api.internal.financial_accounts"
    },
    {
        "rule": "/internal/financial_accounts/transactions/categories",
        "subdomain": "api",
        "endpoint": "api.internal.financial_accounts.transactions.categories"
    },
    {
        "rule": "/internal/financial_account/<uuid:financial_account_id>/transactions/categories",
        "subdomain": "api",
        "endpoint": "api.internal.financial_accounts.transactions.categories"
    },
    {
        "rule": "/internal/gl_accounts/<uuid:account_id>",
        "subdomain": "api",
        "endpoint": "api.internal.gl_account"
    },
    {
        "rule": "/internal/gl_accounts",
        "subdomain": "api",
        "endpoint": "api.internal.gl_accounts"
    },
    {
        "rule": "/internal/invoice/",
        "subdomain": "api",
        "endpoint": "api.internal.invoice"
    },
    {
        "rule": "/internal/automations/invoice/<uuid:entity_id>/automations/<uuid:automation_id>",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.automation"
    },
    {
        "rule": "/internal/automations/invoice/<uuid:entity_id>/automations",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.automations"
    },
    {
        "rule": "/internal/invoice/batch/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.batch"
    },
    {
        "rule": "/internal/invoice/batches",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.batches"
    },
    {
        "rule": "/internal/invoice/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.invoice"
    },
    {
        "rule": "/internal/invoice/<id>/attachments",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.invoice.attachments"
    },
    {
        "rule": "/internal/invoice/line_items",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.line_items"
    },
    {
        "rule": "/internal/invoice/payable",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.payable"
    },
    {
        "rule": "/internal/invoice/payments",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.payments"
    },
    {
        "rule": "/internal/invoice/property_invoices",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.property_invoices"
    },
    {
        "rule": "/internal/invoice/<uuid:id>/reverse",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.reverse"
    },
    {
        "rule": "/internal/invoice/reverse_many",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.reverse_many"
    },
    {
        "rule": "/internal/invoice/terms/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.term"
    },
    {
        "rule": "/internal/invoice/terms",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.terms"
    },
    {
        "rule": "/internal/invoice/transactions",
        "subdomain": "api",
        "endpoint": "api.internal.invoice.transactions"
    },
    {
        "rule": "/internal/accounting/journal_entries",
        "subdomain": "api",
        "endpoint": "api.internal.journal_entries"
    },
    {
        "rule": "/internal/accounting/journal_entries/<uuid:journal_entry_id>",
        "subdomain": "api",
        "endpoint": "api.internal.journal_entry"
    },
    {
        "rule": "/internal/accounting/journal_entries/<uuid:journal_entry_id>/reversal",
        "subdomain": "api",
        "endpoint": "api.internal.journal_entry.reversal"
    },
    {
        "rule": "/internal/marketing/offer/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.marketing.offer"
    },
    {
        "rule": "/internal/marketing/offers",
        "subdomain": "api",
        "endpoint": "api.internal.marketing.offers"
    },
    {
        "rule": "/internal/marketing/search/<uuid:saved_search_id>",
        "subdomain": "api",
        "endpoint": "api.internal.marketing.search"
    },
    {
        "rule": "/internal/marketing/searches",
        "subdomain": "api",
        "endpoint": "api.internal.marketing.searches"
    },
    {
        "rule": "/internal/marketing/unit/<uuid:unit_id>",
        "subdomain": "api",
        "endpoint": "api.internal.marketing.unit"
    },
    {
        "rule": "/internal/marketing/units",
        "subdomain": "api",
        "endpoint": "api.internal.marketing.units"
    },
    {
        "rule": "/internal/marketplace/",
        "subdomain": "api",
        "endpoint": "api.internal.marketplace"
    },
    {
        "rule": "/internal/marketplace/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.marketplace.listing"
    },
    {
        "rule": "/internal/property/occupancies",
        "subdomain": "api",
        "endpoint": "api.internal.occupancies"
    },
    {
        "rule": "/internal/property/occupancy/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.occupancy"
    },
    {
        "rule": "/internal/property/occupancies/<uuid:id>/attachments",
        "subdomain": "api",
        "endpoint": "api.internal.occupancy.attachments"
    },
    {
        "rule": "/internal/automations/occupancy/<uuid:entity_id>/automations/<uuid:automation_id>",
        "subdomain": "api",
        "endpoint": "api.internal.occupancy.automation"
    },
    {
        "rule": "/internal/automations/occupancy/<uuid:entity_id>/automations",
        "subdomain": "api",
        "endpoint": "api.internal.occupancy.automations"
    },
    {
        "rule": "/internal/property/occupancy/<uuid:occupancy_id>/payment/<uuid:occupancy_payment_id>",
        "subdomain": "api",
        "endpoint": "api.internal.occupancy.payment"
    },
    {
        "rule": "/internal/property/occupancy/<uuid:occupancy_id>/payment",
        "subdomain": "api",
        "endpoint": "api.internal.occupancy.payments"
    },
    {
        "rule": "/internal/property/occupancy/<uuid:id>/tenants",
        "subdomain": "api",
        "endpoint": "api.internal.occupancy.tenants"
    },
    {
        "rule": "/internal/property/occupancy/<uuid:occupancy_id>/unpaid_invoices",
        "subdomain": "api",
        "endpoint": "api.internal.occupancy.unpaid_invoices"
    },
    {
        "rule": "/internal/organization",
        "subdomain": "api",
        "endpoint": "api.internal.organization"
    },
    {
        "rule": "/internal/organization/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.organization.by_id"
    },
    {
        "rule": "/internal/organization/groups",
        "subdomain": "api",
        "endpoint": "api.internal.organization.groups"
    },
    {
        "rule": "/internal/people/",
        "subdomain": "api",
        "endpoint": "api.internal.people"
    },
    {
        "rule": "/internal/people/groups",
        "subdomain": "api",
        "endpoint": "api.internal.people.groups"
    },
    {
        "rule": "/internal/people/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.people.person"
    },
    {
        "rule": "/internal/people/<id>/attachments",
        "subdomain": "api",
        "endpoint": "api.internal.people.person.attachments"
    },
    {
        "rule": "/internal/people/<uuid:contact_id>/groups",
        "subdomain": "api",
        "endpoint": "api.internal.people.person.groups"
    },
    {
        "rule": "/internal/plaid/link",
        "subdomain": "api",
        "endpoint": "api.internal.plaid.link"
    },
    {
        "rule": "/internal/plaid/link/token",
        "subdomain": "api",
        "endpoint": "api.internal.plaid.link.token"
    },
    {
        "rule": "/internal/portal/files",
        "subdomain": "api",
        "endpoint": "api.internal.portal.files"
    },
    {
        "rule": "/internal/portal/invoices",
        "subdomain": "api",
        "endpoint": "api.internal.portal.invoices"
    },
    {
        "rule": "/internal/portal/login",
        "subdomain": "api",
        "endpoint": "api.internal.portal.login"
    },
    {
        "rule": "/internal/portal/payments",
        "subdomain": "api",
        "endpoint": "api.internal.portal.payments"
    },
    {
        "rule": "/internal/portal/receipts",
        "subdomain": "api",
        "endpoint": "api.internal.portal.receipts"
    },
    {
        "rule": "/internal/portal/recurring-payments/<uuid:occupancy_id>/<uuid:automation_id>",
        "subdomain": "api",
        "endpoint": "api.internal.portal.recurring_payment"
    },
    {
        "rule": "/internal/portal/recurring-payments",
        "subdomain": "api",
        "endpoint": "api.internal.portal.recurring_payments"
    },
    {
        "rule": "/internal/portal/register",
        "subdomain": "api",
        "endpoint": "api.internal.portal.register"
    },
    {
        "rule": "/internal/portal/screening/connect",
        "subdomain": "api",
        "endpoint": "api.internal.portal.screening.connect"
    },
    {
        "rule": "/internal/portal/screening/exam/<int:exam_id>/submit",
        "subdomain": "api",
        "endpoint": "api.internal.portal.screening.exam.submit"
    },
    {
        "rule": "/internal/portal/screening/exams",
        "subdomain": "api",
        "endpoint": "api.internal.portal.screening.exams"
    },
    {
        "rule": "/internal/portfolio/<uuid:portfolio_id>",
        "subdomain": "api",
        "endpoint": "api.internal.portfolio"
    },
    {
        "rule": "/internal/portfolio/all_properties",
        "subdomain": "api",
        "endpoint": "api.internal.portfolio.all_properties"
    },
    {
        "rule": "/internal/portfolio/<uuid:portfolio_id>/financial_account/<uuid:financial_account_id>",
        "subdomain": "api",
        "endpoint": "api.internal.portfolio.financial_account"
    },
    {
        "rule": "/internal/portfolio/<uuid:portfolio_id>/financial_accounts",
        "subdomain": "api",
        "endpoint": "api.internal.portfolio.financial_accounts"
    },
    {
        "rule": "/internal/portfolio/<uuid:portfolio_id>/owner/<uuid:owner_id>",
        "subdomain": "api",
        "endpoint": "api.internal.portfolio.owner"
    },
    {
        "rule": "/internal/portfolio/<uuid:portfolio_id>/owners",
        "subdomain": "api",
        "endpoint": "api.internal.portfolio.owners"
    },
    {
        "rule": "/internal/portfolio/<uuid:portfolio_id>/properties",
        "subdomain": "api",
        "endpoint": "api.internal.portfolio.properties"
    },
    {
        "rule": "/internal/portfolio/<uuid:portfolio_id>/property/<uuid:property_id>",
        "subdomain": "api",
        "endpoint": "api.internal.portfolio.property"
    },
    {
        "rule": "/internal/portfolio/",
        "subdomain": "api",
        "endpoint": "api.internal.portfolios"
    },
    {
        "rule": "/internal/property/",
        "subdomain": "api",
        "endpoint": "api.internal.properties"
    },
    {
        "rule": "/internal/property/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.property"
    },
    {
        "rule": "/internal/automations/property/<uuid:entity_id>/automations/<uuid:automation_id>",
        "subdomain": "api",
        "endpoint": "api.internal.property.automation"
    },
    {
        "rule": "/internal/automations/property/<uuid:entity_id>/automations",
        "subdomain": "api",
        "endpoint": "api.internal.property.automations"
    },
    {
        "rule": "/internal/property/offer/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.property.offer"
    },
    {
        "rule": "/internal/property/offer/<uuid:id>/attachments",
        "subdomain": "api",
        "endpoint": "api.internal.property.offer.attachments"
    },
    {
        "rule": "/internal/property/offers",
        "subdomain": "api",
        "endpoint": "api.internal.property.offers"
    },
    {
        "rule": "/internal/property/quick/multi",
        "subdomain": "api",
        "endpoint": "api.internal.property.quick.multi"
    },
    {
        "rule": "/internal/property/quick/single",
        "subdomain": "api",
        "endpoint": "api.internal.property.quick.single"
    },
    {
        "rule": "/internal/property/unit/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.property.unit"
    },
    {
        "rule": "/internal/property/<uuid:property_id>/unit/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.property.unit"
    },
    {
        "rule": "/internal/property/<uuid:property_id>/unit/<uuid:id>/attachments",
        "subdomain": "api",
        "endpoint": "api.internal.property.unit.attachments"
    },
    {
        "rule": "/internal/property/units",
        "subdomain": "api",
        "endpoint": "api.internal.property.units"
    },
    {
        "rule": "/internal/property/<uuid:id>/units",
        "subdomain": "api",
        "endpoint": "api.internal.property.units"
    },
    {
        "rule": "/internal/accounting/receivable/bank_deposits/<uuid:bank_deposit_id>",
        "subdomain": "api",
        "endpoint": "api.internal.receivable.bank_deposit"
    },
    {
        "rule": "/internal/accounting/receivable/bank_deposits/<uuid:bank_deposit_id>/deposit_items",
        "subdomain": "api",
        "endpoint": "api.internal.receivable.bank_deposit.deposit_items"
    },
    {
        "rule": "/internal/accounting/receivable/bank_deposit",
        "subdomain": "api",
        "endpoint": "api.internal.receivable.bank_deposits"
    },
    {
        "rule": "/internal/accounting/receivable/invoice/<uuid:receivable_id>",
        "subdomain": "api",
        "endpoint": "api.internal.receivable.invoice"
    },
    {
        "rule": "/internal/accounting/receivable/invoice/batch/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.receivable.invoice.batch"
    },
    {
        "rule": "/internal/accounting/receivable/invoice/batch/<uuid:id>/reversal",
        "subdomain": "api",
        "endpoint": "api.internal.receivable.invoice.batch.reversal"
    },
    {
        "rule": "/internal/accounting/receivable/invoice/batches",
        "subdomain": "api",
        "endpoint": "api.internal.receivable.invoice.batches"
    },
    {
        "rule": "/internal/accounting/receivable/invoice/<uuid:receivable_id>/reversal",
        "subdomain": "api",
        "endpoint": "api.internal.receivable.invoice.reversal"
    },
    {
        "rule": "/internal/accounting/receivable/invoice",
        "subdomain": "api",
        "endpoint": "api.internal.receivable.invoices"
    },
    {
        "rule": "/internal/accounting/receivable/receipt/<uuid:receipt_id>",
        "subdomain": "api",
        "endpoint": "api.internal.receivable.receipt"
    },
    {
        "rule": "/internal/accounting/receivable/receipt/<uuid:receipt_id>/reapply",
        "subdomain": "api",
        "endpoint": "api.internal.receivable.receipt.reapply"
    },
    {
        "rule": "/internal/accounting/receivable/receipt/<uuid:receipt_id>/reversal",
        "subdomain": "api",
        "endpoint": "api.internal.receivable.receipt.reversal"
    },
    {
        "rule": "/internal/accounting/receivable/receipt",
        "subdomain": "api",
        "endpoint": "api.internal.receivable.receipts"
    },
    {
        "rule": "/internal/automations/receivable_charge/<uuid:entity_id>/automations/<uuid:automation_id>",
        "subdomain": "api",
        "endpoint": "api.internal.receivable_charge.automation"
    },
    {
        "rule": "/internal/automations/receivable_charge/<uuid:entity_id>/automations",
        "subdomain": "api",
        "endpoint": "api.internal.receivable_charge.automations"
    },
    {
        "rule": "/internal/automations/receivable_credit/<uuid:entity_id>/automations/<uuid:automation_id>",
        "subdomain": "api",
        "endpoint": "api.internal.receivable_credit.automation"
    },
    {
        "rule": "/internal/automations/receivable_credit/<uuid:entity_id>/automations",
        "subdomain": "api",
        "endpoint": "api.internal.receivable_credit.automations"
    },
    {
        "rule": "/internal/automations/receivable_invoice/<uuid:entity_id>/automations/<uuid:automation_id>",
        "subdomain": "api",
        "endpoint": "api.internal.receivable_invoice.automation"
    },
    {
        "rule": "/internal/automations/receivable_invoice/<uuid:entity_id>/automations",
        "subdomain": "api",
        "endpoint": "api.internal.receivable_invoice.automations"
    },
    {
        "rule": "/internal/automations/receivable_prepayment/<uuid:entity_id>/automations/<uuid:automation_id>",
        "subdomain": "api",
        "endpoint": "api.internal.receivable_prepayment.automation"
    },
    {
        "rule": "/internal/automations/receivable_prepayment/<uuid:entity_id>/automations",
        "subdomain": "api",
        "endpoint": "api.internal.receivable_prepayment.automations"
    },
    {
        "rule": "/internal/automations/receivable_receipt/<uuid:entity_id>/automations/<uuid:automation_id>",
        "subdomain": "api",
        "endpoint": "api.internal.receivable_receipt.automation"
    },
    {
        "rule": "/internal/automations/receivable_receipt/<uuid:entity_id>/automations",
        "subdomain": "api",
        "endpoint": "api.internal.receivable_receipt.automations"
    },
    {
        "rule": "/internal/automations/receivable_receipt_payment/<uuid:entity_id>/automations/<uuid:automation_id>",
        "subdomain": "api",
        "endpoint": "api.internal.receivable_receipt_payment.automation"
    },
    {
        "rule": "/internal/automations/receivable_receipt_payment/<uuid:entity_id>/automations",
        "subdomain": "api",
        "endpoint": "api.internal.receivable_receipt_payment.automations"
    },
    {
        "rule": "/internal/report/batch/<uuid:batch_id>",
        "subdomain": "api",
        "endpoint": "api.internal.report.batch"
    },
    {
        "rule": "/internal/report/batch",
        "subdomain": "api",
        "endpoint": "api.internal.report.batch.create"
    },
    {
        "rule": "/internal/report/<string:name>",
        "subdomain": "api",
        "endpoint": "api.internal.report.report3"
    },
    {
        "rule": "/internal/report/<string:name>.<format>",
        "subdomain": "api",
        "endpoint": "api.internal.report.report3.export"
    },
    {
        "rule": "/internal/report/<string:name>/pending/<uuid:task_id>",
        "subdomain": "api",
        "endpoint": "api.internal.report.report3.pending"
    },
    {
        "rule": "/internal/report/<string:name>/<uuid:report_id>",
        "subdomain": "api",
        "endpoint": "api.internal.report.report3.report"
    },
    {
        "rule": "/internal/report/<string:name>/save",
        "subdomain": "api",
        "endpoint": "api.internal.report.report3.save"
    },
    {
        "rule": "/internal/property/screening/request/<id>",
        "subdomain": "api",
        "endpoint": "api.internal.screening.request"
    },
    {
        "rule": "/internal/property/screening/request/<id>/cancel",
        "subdomain": "api",
        "endpoint": "api.internal.screening.request.cancel"
    },
    {
        "rule": "/internal/property/screening/request/<id>/pull_reports",
        "subdomain": "api",
        "endpoint": "api.internal.screening.request.pull_reports"
    },
    {
        "rule": "/internal/property/screening/requests",
        "subdomain": "api",
        "endpoint": "api.internal.screening.requests"
    },
    {
        "rule": "/internal/search",
        "subdomain": "api",
        "endpoint": "api.internal.search"
    },
    {
        "rule": "/internal/settings/cash_management_rule/<uuid:cash_management_rule_id>",
        "subdomain": "api",
        "endpoint": "api.internal.settings.cash_management_rule"
    },
    {
        "rule": "/internal/settings/cash_management_rules",
        "subdomain": "api",
        "endpoint": "api.internal.settings.cash_management_rules"
    },
    {
        "rule": "/v1/shareable/manualauthentication/status",
        "subdomain": "api",
        "endpoint": "api.internal.transunion.authentication"
    },
    {
        "rule": "/v1/shareable/reports/status",
        "subdomain": "api",
        "endpoint": "api.internal.transunion.reports"
    },
    {
        "rule": "/v1/shareable/system",
        "subdomain": "api",
        "endpoint": "api.internal.transunion.system_status"
    },
    {
        "rule": "/internal/automations/unit/<uuid:entity_id>/automations/<uuid:automation_id>",
        "subdomain": "api",
        "endpoint": "api.internal.unit.automation"
    },
    {
        "rule": "/internal/automations/unit/<uuid:entity_id>/automations",
        "subdomain": "api",
        "endpoint": "api.internal.unit.automations"
    },
    {
        "rule": "/internal/property/<uuid:unit_id>/utilities",
        "subdomain": "api",
        "endpoint": "api.internal.unit.utilities"
    },
    {
        "rule": "/internal/property/<uuid:unit_id>/utilities/<uuid:utility_id>",
        "subdomain": "api",
        "endpoint": "api.internal.unit.utility"
    },
    {
        "rule": "/internal/user/",
        "subdomain": "api",
        "endpoint": "api.internal.user"
    },
    {
        "rule": "/internal/user/email",
        "subdomain": "api",
        "endpoint": "api.internal.user.email_exists"
    },
    {
        "rule": "/internal/user/forgot_password",
        "subdomain": "api",
        "endpoint": "api.internal.user.forgot_password"
    },
    {
        "rule": "/internal/user/login_token",
        "subdomain": "api",
        "endpoint": "api.internal.user.login_token"
    },
    {
        "rule": "/internal/user/portal_login_token",
        "subdomain": "api",
        "endpoint": "api.internal.user.portal_login_token"
    },
    {
        "rule": "/internal/user/reset_password",
        "subdomain": "api",
        "endpoint": "api.internal.user.reset_password"
    },
    {
        "rule": "/internal/automations/work_order/<uuid:entity_id>/automations/<uuid:automation_id>",
        "subdomain": "api",
        "endpoint": "api.internal.work_order.automation"
    },
    {
        "rule": "/internal/automations/work_order/<uuid:entity_id>/automations",
        "subdomain": "api",
        "endpoint": "api.internal.work_order.automations"
    },
    {
        "rule": "/internal/workorder/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.workorder"
    },
    {
        "rule": "/internal/workorder/<uuid:id>/accept",
        "subdomain": "api",
        "endpoint": "api.internal.workorder.accept"
    },
    {
        "rule": "/internal/workorder/<uuid:id>/attachments",
        "subdomain": "api",
        "endpoint": "api.internal.workorder.attachments"
    },
    {
        "rule": "/internal/workorder/batch/<uuid:id>",
        "subdomain": "api",
        "endpoint": "api.internal.workorder.batch"
    },
    {
        "rule": "/internal/workorder/batches",
        "subdomain": "api",
        "endpoint": "api.internal.workorder.batches"
    },
    {
        "rule": "/internal/workorder/<uuid:id>/submit",
        "subdomain": "api",
        "endpoint": "api.internal.workorder.submit"
    },
    {
        "rule": "/internal/workorder/",
        "subdomain": "api",
        "endpoint": "api.internal.workorders"
    },
    {
        "rule": "/marketing/offer/<uuid:offer_id>",
        "subdomain": "api",
        "endpoint": "api.marketing.offer"
    },
    {
        "rule": "/marketing/offers",
        "subdomain": "api",
        "endpoint": "api.marketing.offers"
    },
    {
        "rule": "/marketing/unit/<uuid:unit_id>",
        "subdomain": "api",
        "endpoint": "api.marketing.unit"
    },
    {
        "rule": "/marketing/units",
        "subdomain": "api",
        "endpoint": "api.marketing.units"
    },
    {
        "rule": "/membership",
        "subdomain": "api",
        "endpoint": "api.membership"
    },
    {
        "rule": "/token/membership/<uuid:membership_id>",
        "subdomain": "api",
        "endpoint": "api.membership_token"
    },
    {
        "rule": "/status",
        "subdomain": "api",
        "endpoint": "api.status"
    },
    {
        "rule": "/token/refresh",
        "subdomain": "api",
        "endpoint": "api.token_refresh"
    },
    {
        "rule": "/user",
        "subdomain": "api",
        "endpoint": "api.user"
    },
    {
        "rule": "/_version",
        "subdomain": "",
        "endpoint": "api.version"
    },
    {
        "rule": "/invoice/print_batch.pdf",
        "subdomain": "",
        "endpoint": "invoice.print_batch"
    },
    {
        "rule": "/workorder/batch/create/properties",
        "subdomain": "",
        "endpoint": "workorder.batch.create_properties"
    },
    {
        "rule": "/workorder/print_batch.pdf",
        "subdomain": "",
        "endpoint": "workorder.print_batch"
    }
];
