let startPoint: { clientX: number; clientY: number } = null;

window.addEventListener("touchstart", function touchstart(e) {
    // If starting near the left 20% of the screen
    const clientWidth = document.body.clientWidth;
    const isTouchLeftQuadrant = e.targetTouches[0].clientX < 0.4 * clientWidth;

    if (isTouchLeftQuadrant) {
        startPoint = e.targetTouches[0];
    }
});
window.addEventListener("touchmove", function touchmove(e) {
    if (!startPoint) return;

    // If we swipe any direction but right
    if (e.targetTouches[0].clientX < startPoint.clientX) {
        startPoint = null;
        return;
    }
});
window.addEventListener("touchend", function touchend(e) {
    if (!startPoint) return;

    const { clientY, clientX } = e.changedTouches[0];
    const minimumDeltaX = document.body.clientWidth * 0.2;

    // If we moved at least 20% the width of the screen
    if (clientX - minimumDeltaX > startPoint.clientX) {
        // And we moved more in the X than the Y
        if (Math.abs(clientX - startPoint.clientX) > Math.abs(clientY - startPoint.clientY)) {
            // Open the sidebar
            $(".sidebar").addClass("open");
            e.preventDefault();
        }
    }

    startPoint = null;
});
window.addEventListener("touchcancel", function touchcancel() {
    startPoint = null;
});
