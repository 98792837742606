(() => {
    /********************************************************
     * Here is the search logic for universal search.
     * Should remove classes and the search enter button if
     * select2 initializes properly
     ********************************************************/

    const ICONS = {
        property_singleproperty: '<i class="fa fa-fw fa-home"></i> ',
        property_multipleproperty: '<i class="fa fa-fw fa-cubes"></i> ',
        unit: '<i class="fa fa-fw fa-cube"></i> ',

        contact_tenant: '<i class="fa fa-fw fa-child"></i>',
        contact_manager: '<i class="fa fa-fw fa-user"></i>',
        contact_vendor: '<i class="fa fa-fw fa-user-md"></i>',
        contact_owner: '<i class="fa fa-fw fa-user"></i>',

        workorder: '<i class="fa fa-fw fa-wrench"></i>',
        invoice: '<i class="fa fa-fw fa-file-text-o"></i>',

        application: '<i class="fa fa-fw fa-file-text"></i>',
        occupancy: '<i class="fa fa-fw fa-user-circle"></i>',

        admin_user: '<i class="fa fa-fw fa-user"></i>',
        admin_role: '<i class="fa fa-fw fa-users"></i>',
        admin_organization: '<i class="fa fa-fw fa-building"></i>',
        admin_transaction: '<i class="fa fa-fw fa-money"></i>',
    };

    $("#search-query")
        .select2({
            id: "url",
            minimumInputLength: 1,
            dropdownCss: () => {
                const elem = $("#s2id_search-query");
                const docWidth = $(document).width();
                let { left } = elem.offset();
                let right = docWidth - (left + elem.outerWidth());

                return {
                    position: "absolute",
                    background: "white",
                    ...(right - left < 200
                        ? { left: "1em", width: `calc(100vw - 2em)` }
                        : left < right
                        ? { left: `${left}px`, width: `calc(100vw - ${left * 2}px)` }
                        : { right: `${right}px`, width: `calc(100vw - ${right * 2}px` }),
                };
            },
            dropdownCssClass: "search-results-dropdown",
            ajax: {
                quietMillis: 130,
                dataType: "json",
                url: () => router.route("api.internal.search"),
                data: (term) => ({ term }),
                results: (data) => data,
            },
            formatResult: (result) => {
                let markup = "";
                //Search result
                markup += "<strong>";

                if (ICONS[result.type] !== undefined) {
                    markup += ICONS[result.type];
                }

                markup += " " + result.title + "</strong>";
                if (result.subtitle) {
                    markup += ' <small class="text-muted">' + result.subtitle + "</small>";
                }

                if (result.age) {
                    markup +=
                        ' <small class="text-muted pull-right">updated ' +
                        $.timeago(DateTime.fromISO(result.age).toJSDate()) +
                        "</small>";
                }

                if (result.description) {
                    markup += "<br>" + result.description.truncate(200);
                }

                return markup;
            },
            formatSelection: (result) => {
                if (result.url !== "undefined") {
                    document.location = result.url;
                } else {
                    return result.text;
                }
            },
        })
        .on("select2-opening", () => {
            const elem = $("#s2id_search-query");
            elem.data("width", elem.width());
        });

    $("#open-search").on("click", (e) => {
        $("#search-query").select2("open");
        e.preventDefault();
    });

    $(document).on("keydown", (e) => {
        if (
            document.activeElement.tagName === "INPUT" ||
            e.target.isContentEditable ||
            $(e.target).is(":input")
        ) {
            return true;
        }

        if (
            e.keyCode === 32 || //Space
            e.keyCode === 13 || //Return
            (e.keyCode === 83 && e.altKey)
        ) {
            // alt + s
            $("#search-query").select2("open");
            return false;
        }

        return true;
    });
})();
