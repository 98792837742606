/* FIXME: BS5 */
(function (select2) {
    select2.unit = {
        allowClear: true,
        closeOnSelect: false,
        minimumInputLength: 0,
        ajax: {
            url: function () {
                const args = {};

                if (this.data("property_id")) {
                    args["id"] = this.data("property_id");
                }

                return router.route("api.internal.property.units", args);
            },
            dataType: "json",
            quietMillis: 100,
            data: (term, page) => ({ term, page }),
            results: (data) => {
                return {
                    ...data,
                    results: data.results,
                };
            },
        },
        formatResult: function (unit) {
            if (this.processResult) {
                this.processResult(unit);
            }

            return `<strong>${unit.name}</strong> ${Formatters.address_short(unit)}`;
        },
        formatSelection: (unit) => `${unit.name} - ${Formatters.address_short(unit)}`,
        initSelection: select2.makeInitSelection((args) =>
            router.route("api.internal.property.unit", args),
        ),
    };
    select2.unit_multiple = $.extend({ multiple: true }, select2.unit);
})(window.comanage.select2);
